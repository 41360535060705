import React, {useState} from 'react'
import {EmployerData} from "../../consts"
import {useDispatch, useSelector} from "react-redux"
import {clearStepError, setEmployer} from "../../../../store/CvEditor/cvEditor.actions"
import Error from "../../../global/Error/Error"

const EmployerForm = ({index, deleteEmployer, error}: {index: number, deleteEmployer: () => void, error?: string}) => {
  const dispatch = useDispatch()

  const Input = ({fieldName, label, error}: {fieldName: string, label: string, error?: string}) => {
    const employer: EmployerData = useSelector((state: Storage) => state.cvEditor.CVs?.[index]?.employer)
    const [errorMessage, setErrorMessage] = useState(error)
    if (!employer) return null
    return (
      <div className='relative'>
        <input
          type="text"
          id={fieldName}
          autoFocus
          value={employer[fieldName as keyof EmployerData]}
          placeholder="Enter prospective employer name"
          className="bg-white border border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 px-4 focus:outline-none"
          onChange={event => {
            setErrorMessage('')
            dispatch(clearStepError())
            dispatch(setEmployer({index: index, data: {...employer, [fieldName]: event.target.value}}))
          }}
        />
        <button
          type='button'
          onClick={() => {
            dispatch(clearStepError())
            deleteEmployer()
          }}
          className='absolute -right-2 -top-2 w-5 h-5 bg-white border-[1.5px] rounded-full flex items-center justify-center border-neutral font-semibold text-md duration-200 hover:bg-neutral hover:text-white'
        >
          &#x2715;
        </button>
        <Error errorMessage={errorMessage || ''}/>
      </div>
    )
  }

  return <div className='mb-5'><Input fieldName="company" label="Company" error={error}/></div>
}

export default EmployerForm