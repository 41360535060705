import {instance} from "../utils/axios";
import {handleError} from "./functions";

export const getUserHandler = async (userIdToken: string, userAccessToken: string) => {
  try {
    const responseFromApi = await instance.get("user", {
      headers: {
        Authorization: `Bearer idToken:${userIdToken} accessToken:${userAccessToken}`,
      },
    });
    if (responseFromApi.status === 200) {
      return {
        status: 200,
        userData: responseFromApi.data,
      };
    } else {
      return {
        status: responseFromApi.status,
        userData: null,
      };
    }
  } catch (error) {
    handleError(error)
    console.log("Error in updating the name");
  }
};

export const deleteUserHandler = async (
  userIdToken: string,
  userAccessToken: string
) => {
  try {
    const responseFromApi = await instance.delete("user", {
      headers: {
        Authorization: `Bearer idToken:${userIdToken} accessToken:${userAccessToken}`,
      },
    });
    if (responseFromApi.status === 204) {
      return 204;
    } else {
      return responseFromApi.status;
    }
  } catch (error) {
    handleError(error)
    console.log("Error in updating the name");
  }
};

export const updateUserNameHandler = async (
  userIdToken: string,
  updateUserName: string,
  userAccessToken: string
) => {
  try {
    const responseFromApi = await instance.post(
      "user/update-user-name",
      {name: updateUserName},
      {
        headers: {
          Authorization: `Bearer idToken:${userIdToken} accessToken:${userAccessToken}`,
        },
      }
    );
    if (responseFromApi.status === 204) {
      return 204;
    } else {
      return responseFromApi.status;
    }
  } catch (error) {
    handleError(error)
    console.log("Error in updating the name");
  }
};

