import {
  useCheckBrowser,
  useWarningToggle,
  useWarningVisible,
} from "../../../hooks/useDeviceDetect";
import { isChrome,isDesktop } from 'react-device-detect'
export default function AppWarning() {
  const toggleWarning = useWarningToggle();
  const showURLWarning = useWarningVisible();
  const isPrivate  = useCheckBrowser();
  return !isDesktop || isPrivate || !isChrome ? (
    <div
      className={`w-full relative inset-x-0 gap-4 p-4 bg-dark-400 text-start md:text-center text-sm font-bold justify-start md:justify-center align-center text-neutral  ${showURLWarning ? "flex" : "hidden"
        }`}
    >
      <div className="flex flex-col gap-4">
        {!isDesktop && <Warnings index={0} />}
        {isPrivate && <Warnings index={1} />}
        {!isChrome && <Warnings index={2} />}
      </div>
      <button className="text-xl font-bold absolute right-5 top-3" onClick={toggleWarning}>&times;</button>
    </div>
  ) : null;
}


const Warnings = ({ index }: { index: number }) => {
  return (
    <div className="mr-4">
      {notify[index].description}
      <span className="text-neutral">{notify[index].action}</span>
    </div>
  );
};

const notify = [
  {
    action: "",
    description: `Please note, you can manage your videoCVpages on all devices, 
    but recording videos only works with a laptop/desktop where a camera is included.`,
  },
  {
    action: "Please switch now.",
    description: `You are in incognito mode. 
    The tool will only work properly in normal mode. 
    `,
  },

  {
    action: "Please switch now.",
    description: `You are trying to use videoCVpage on another browser than Chrome. 
    The tool will only work properly with Chrome. `,
  },
];
