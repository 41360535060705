import {initializeApp} from "firebase/app"
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth"
import {instance} from "./axios"
import store from "../store/createStore"
import {
  addUserData,
  destroyAccessToken,
  destroyIdToken,
  removerUserData,
  setAccessToken,
  setIdToken
} from "../store/User/user.actions"
import {home} from "../consts/links"
import {getUserHandler} from "../utils/useraccount"
import {handleAuthErrors} from "./functions"
import {fireAnalyticsEvent} from "./analytics"
import {getAnalytics} from "firebase/analytics"
import {analyticsEvents} from "../consts/analyticsEvents"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_API_MEASUREMENT_ID
}

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider()

const processLogin = async (user: any, name: string | null, isGoogleSignedIn: string | null, idToken: string) => {
  if (user.email) {
    try {
    const registrationResponse = await requestRegistration(user.uid, user.email, name)
    store.dispatch(setAccessToken(registrationResponse.data.token))
    const accessToken = registrationResponse.data.token;
    const responseForUser = await getUserHandler(idToken, accessToken);
    store.dispatch(
      addUserData({
        name:
          responseForUser?.userData?.name || responseForUser?.userData?.email,
        email: responseForUser?.userData?.email,
        isGoogleSignedIn: isGoogleSignedIn,
      })
    );
    window.location.href = home
    }
    catch (err: any) {throw new Error(err)}
  }
  return false;
}

const requestRegistration = async (id: string, email: string, name: string | null) => {
  if (!name) name = email
  try {
    return await instance({url: "auth/login", method: "POST", data: {id: id, email: email, name: name}})
  }
  catch (err: any) {throw new Error(err)}
}

const signInWithGoogle = async (registering: boolean) => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const responseWithTokens: any = res
    store.dispatch(setIdToken(responseWithTokens._tokenResponse.idToken))
    await processLogin(user, user.displayName,"TRUE", responseWithTokens._tokenResponse.idToken)
    fireAnalyticsEvent(registering ? analyticsEvents.REGISTER_USER : analyticsEvents.LOGIN_USER)
  }
  catch (err: any) {throw new Error(handleAuthErrors(err.code))}
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const res: any = await signInWithEmailAndPassword(auth, email, password)
    store.dispatch(setIdToken(res._tokenResponse.idToken))
    await processLogin(res.user, res.user.name, "FALSE", res._tokenResponse.idToken )
    fireAnalyticsEvent(analyticsEvents.LOGIN_USER)
  }
  catch (err: any) {throw new Error(handleAuthErrors(err.code))}
}

const registerWithEmailAndPassword = async (name: any, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    const responseWithTokens: any = res
    store.dispatch(setIdToken(responseWithTokens._tokenResponse.idToken))
    await processLogin(user, name, "FALSE",responseWithTokens._tokenResponse.idToken)
    fireAnalyticsEvent(analyticsEvents.REGISTER_USER)
  }
  catch (err: any) {throw new Error(handleAuthErrors(err.code))}
}

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert("Password reset link sent!")
  } catch (err: any) {throw new Error(err.message)}
}

const logout = () => {
  store.dispatch(destroyIdToken())
  store.dispatch(destroyAccessToken())
  store.dispatch(removerUserData())
  signOut(auth)
}

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
}