import React, {Fragment} from 'react'
import {Menu, Transition} from "@headlessui/react"
import {combineClassNames} from "../../../utils/functions"

const DropdownButton = ({label, buttons}: {label: string, buttons: {label: string, action: () => void}[]}) => {
  return (
    <Menu as="div" className="relative inline-block text-left w-1/2">
      <div className='flex items-center w-full'>
        <Menu.Button>
          <div className='bg-white border border-gray-800 sm:text-sm rounded-lg block w-full py-1 px-3 font-semibold appearance-none focus:outline-none bg-select bg-no-repeat bg-[left_93%_center] pr-12'>{label}</div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-lg focus:outline-none">
          <div className="py-2">
            {buttons.map((button, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    onClick={button.action}
                    className={combineClassNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm font-bold duration-150'
                    )}
                  >
                    {button.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropdownButton