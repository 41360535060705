import userTypes from './user.types'

const INITIAL_STATE = {
  idToken: localStorage.getItem('idToken') || null,
  accessToken: localStorage.getItem('accessToken') || null,
  name: "",
  email: "",
  isGoogleSignedIn: localStorage.getItem("isGoogleSignedIn") || null,
}

const userReducer = (state= INITIAL_STATE, action: any) => {
  switch (action.type) {
    case userTypes.SET_ID_TOKEN: {
      localStorage.setItem('idToken', action.value)
      return { ...state, idToken: action.value }
    }
    case userTypes.DESTROY_ID_TOKEN: {
      localStorage.removeItem('idToken')
      localStorage.removeItem("isGoogleSignedIn")
      return { ...state, idToken: null }
    }
    case userTypes.SET_ACCESS_TOKEN: {
      localStorage.setItem('accessToken', action.value)
      return { ...state, accessToken: action.value }
    }
    case userTypes.DESTROY_ACCESS_TOKEN: {
      localStorage.removeItem('accessToken')
      localStorage.removeItem("isGoogleSignedIn")
      return { ...state, accessToken: null }
    }
    case userTypes.ADD_USER_DATA: {
      localStorage.setItem(
          "isGoogleSignedIn",
          action.userData.isGoogleSignedIn
      );
      return {
        ...state,
        name: action.userData.name,
        email: action.userData.email,
        isGoogleSignedIn: action.userData.isGoogleSignedIn,
      };
    }
    case userTypes.REMOVE_USER_DATA: {
      return {
        ...state,
        name: "",
        email: "",
        isGoogleSignedIn: false,
      };
    }
    case userTypes.ADD_USER_DATA_UPDATE: {
      return {
        ...state,
        name: action.userData.name,
        email: action.userData.email,
      };
    }
    case userTypes.UPDATE_USER_DATA: {
      return {
        ...state,
        name: action.userData.name,
      };
    }
    default: return state
  }
}

export default userReducer
