import appTypes from './app.types'

const INITIAL_STATE = {
 warningShow: true
}

export const warningReducer = (state= INITIAL_STATE, action: any) => {
  switch (action.type) {
    case appTypes.VISIBILITY: {
        return { ...state, warningShow: !state.warningShow }
      }
    default: return state
  }
}

