import {combineReducers} from 'redux'
import cvEditorReducer from './CvEditor/cvEditor.reducer'
import userReducer from "./User/user.reducer"
import { warningReducer } from './app/app.reducer'

export default combineReducers({
  cvEditor: cvEditorReducer,
  user: userReducer,
  app:warningReducer
})
