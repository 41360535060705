import PrimaryLayout from "../../layouts/PrimaryLayout/PrimaryLayout";
import React from "react";
import { quick_help } from "../../consts/support";
const Support = () => {
  return (
    <PrimaryLayout>
      <div className="py-8 flex flex-col">
        <div className="flex flex-col gap-9">
          <div className="flex gap-5 items-center">
            <img src="/images/support.svg" alt="support icon" />
            <h1 className="text-xl md:text-2xl font-bold text-neutral">
              Support
            </h1>
          </div>
          <div className="flex flex-col gap-8">
            {quick_help.map((support) => (
              <div className="flex flex-col gap-3" key={support.title}>
                <p className="block text-base font-bold text-neutral">
                  {support.title}
                </p>
                <div className="flex gap-4 items-center">
                  <img src={support.image_url} alt={support.alt} />
                  <p className="font-semibold  text-sm">
                    <a
                      className="underline"
                      href={support.linkto}
                      target="blank"
                    >
                      {support.text}
                    </a>

                    {support.supportive_text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default Support;
