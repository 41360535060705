import React from "react"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"

const GoogleButton = ({children, id, onClick, disabled, loading} : {
  children: any, id?: string, onClick?: () => void, disabled?: boolean, loading?: boolean
}) => (
  <button
    id={id}
    className={
      `
        flex
        items-center
        justify-center
        bg-white
        border
        border-error
        text-center
        text-sm
        rounded-xl
        ${loading ? 'pl-11 pr-6' : 'px-6'}
        h-11
        text-error
        font-extrabold
        duration-300
        transition-colors
        hover:text-error
        active:scale-95
      `
    }
    disabled={disabled}
    onClick={onClick}
  >
    {loading ?
      <div className="flex" style={{marginLeft: '-24px'}}>
        <LoadingSpinner small google/> Loading
      </div> :
      children
    }
  </button>
)

export default GoogleButton