import React, {useEffect, useState} from 'react'
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import {useAuthState} from "react-firebase-hooks/auth"
import {auth} from "../../utils/firebase"
import {useNavigate} from "react-router-dom"
import {register} from "../../consts/links"
import Loading from "../Loading/Loading"

const PrimaryLayout = (props: { children: any, editorMode?: boolean }) => {

  const [user, userLoading] = useAuthState(auth)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(userLoading)
    !userLoading && !user && navigate(register)
  }, [navigate, user, userLoading])

  if (loading) return <Loading/>

  return (
    <div className="min-h-screen flex flex-col antialiased text-neutral">
      <Header editorMode={props.editorMode} />
      <div className={props.editorMode ? "flex-1 bg-base-300 flex flex-col" : "mx-auto bg-white w-full max-w-screen-2xl px-3 md:px-5 flex-1"}>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default PrimaryLayout