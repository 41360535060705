import React, {useEffect, useState} from "react"
import PreviewCV from "../PreviewCV/PreviewCV"
import {CVData} from "../consts"
import {useSelector} from "react-redux"

const PreviewGallery = (
  {onPrimarySlideChange, currentSlide, setCurrentSlide} :
  {onPrimarySlideChange?: (index: number) => void, currentSlide?: number, setCurrentSlide?: (value: number) => void}
) => {

  const CVs: CVData[] = useSelector((state: Storage) => state.cvEditor?.CVs)
  const [primarySlide, setPrimarySlide] = useState(CVs.length && CVs.length - 1)

  useEffect(() => {
    (currentSlide === 0 || currentSlide) && setPrimarySlide(currentSlide)
  }, [currentSlide])

  useEffect(() => {
    CVs[primarySlide]?.removed && setPrimarySlide(
      CVs.filter(cv => !cv.removed).length ? CVs.findIndex(cv => !cv.removed) : 0
    )
  }, [CVs, primarySlide])

  return (
    <div className="w-full flex-shrink-0 bg-slate-500 px-2 md:px-8 pt-6 pb-8 xl:col-span-7 overflow-auto">
      <h5 className="text-center mb-6 text-white text-base font-bold">Page preview</h5>
      <div className="flex flex-wrap gap-x-5">
        {CVs.map((employer, index) => !employer.removed && index !== primarySlide && (
          <div
            className="w-28 h-36 relative mb-6 bg-white border border-dashed border-gray-800 flex items-center justify-center"
            key={index}
            onClick={() => {
              setCurrentSlide && setCurrentSlide(index)
              onPrimarySlideChange && onPrimarySlideChange(index)
              setPrimarySlide(index)
            }}
          >
            <button
              type="button"
              className="absolute shadow-md -top-2 -left-2 max-w-[123px] text-center bg-white border border-gray-800 text-sm rounded-lg px-3 sm:px-4 h-10 truncate font-bold duration-300 transition-colors active:scale-95"
            >
              {employer.employer.company}
            </button>
            {/* <PreviewCV index={index} thumbnail/> */}
            <div className="text-primary font-bold text-sm px-5 underline cursor-pointer">
              <img className="mb-2" src="/images/edit.svg" alt="logo"/> Enlarge & edit
            </div>
          </div>
        ))}
      </div>
      {CVs.filter(cv => !cv.removed).length ?
        <PreviewCV index={CVs[primarySlide]?.removed ? primarySlide + 1 : primarySlide}/> : null
      }
    </div>
  )
}

export default PreviewGallery