import React, { useState } from 'react'
import { setPublished } from "../../../store/CvEditor/cvEditor.actions"
import { CVData } from "../consts"
import { useDispatch } from "react-redux"
import { instance } from "../../../utils/axios"
import store from "../../../store/createStore"
import { handleError } from "../../../utils/functions"
import Alert from "../../Alert/Alert";
import PrimaryButton from "../../global/PrimaryButton/PrimaryButton";
import TertiaryButton from "../../global/TertiaryButton/TertiaryButton";

const CVCard = (
  {
    cv, index, onDelete, onEdit, onError
  } : {
    cv: CVData, index: number, onDelete: () => void, onEdit: () => void, onError?: (err: any) => void
  }
) => {

  const [deleting, setDeleting] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] = useState<boolean>(false)
  const dispatch = useDispatch()

  const VisibilitySwitcher = ({index, cv} : {index: number, cv: CVData}) => {
    const [value, setValue] = useState<boolean>(cv.published || false)
    const [loading, setLoading] = useState<boolean>(false)
    return (
      <div onClick={event => event.stopPropagation()}>
        <label htmlFor={"published" + index} className="inline-flex relative items-center cursor-pointer">
          <input
            type="checkbox"
            checked={value}
            id={"published" + index}
            className="sr-only peer"
            disabled={loading}
            onChange={async () => {
              if (loading) return
              setLoading(true)
              try {
                const idToken = store.getState().user?.idToken
                const accessToken = store.getState().user?.accessToken
                const response = await instance.post(
                  "cv/change-visibility",
                  {id: cv.id, published: !value},
                  {headers: {"Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}`}}
                )
                if (response.status === 200) {
                  dispatch(setPublished({index: index, data: response.data.published}))
                  setValue(response.data.published)
                  setLoading(false)
                }
                else alert('something is wrong')
              } catch (err: any) {
                setLoading(false)
                onError ? onError(err) : handleError(err)
              }
            }}
          />
          <div className="w-14 h-7 bg-white peer-focus:outline-none rounded-full border-2 border-gray-500 duration-200 peer peer-checked:after:translate-x-full peer-checked:after:border-gray-500 after:content-[''] after:absolute after:top-1 after:left-1 after:bg-red-600  after:rounded-full after:h-5 after:w-6 after:transition-all peer-checked:after:bg-success"/>
          <span className={`${value ? 'text-success' : 'text-red-600'} ml-2.5 text-sm font-bold`}>
            {value ? 'Published' : 'Unpublished'}
          </span>
        </label>
      </div>
    )
  }

  return (
    <div onClick={() => cv.id && window.open(
      process.env.NODE_ENV === 'development' ?
        `/${cv.id}` :
        `https://my.${window.location.host?.replace('app.', '')}/${cv.id}`,'_blank'
    )} className="border border-gray-400 bg-white rounded-2xl p-4 relative cursor-pointer" key={cv.id}>
      <button
        className='absolute -right-4 -top-4 active:scale-95'
        disabled={deleting}
        onClick={(event) => {
          event.stopPropagation()
          setDeleteConfirmationOpened(true)
        }}
      >
        <img src="/images/remove.svg" alt="remove" />
      </button>
      <div className='flex gap-x-6'>
        <div className='shadow-md text-center truncate w-48 leading-9 bg-white border border-gray-800 text-sm rounded-lg px-3 sm:px-4 h-10 font-bold'>{cv.employer.company}</div>
        <div className='flex flex-col gap-y-3'>
        <VisibilitySwitcher index={index} cv={cv} />
        <div className='flex flex-col'>
          <div className='flex gap-x-2.5'>
              <button className='flex-shrink-0 active:scale-95' onClick={(event) => {
                event.stopPropagation()
                navigator.clipboard.writeText(`https://my.videocvpage.com/${cv.id}`)
                setCopied(true)
                setTimeout(() => setCopied(false), 3000)
              }}>
                <img src="/images/copy.svg" alt="copy"/>
              </button>
              <div className='text-primary text-md font-bold underline'>my.videocvpage.com/{cv.id}</div>
            </div>
            <div className='mt-0.5 text-xxs font-light'>{copied ? 'Copied to clipboard' : 'Click to copy'}</div>
          </div>
          <button className='flex items-center gap-x-2.5' onClick={(event) => {
            event.stopPropagation()
            onEdit()
          }}>
            <img src="/images/editgray.svg" alt="copy"/> 
            <div className='text-md font-semibold underline'>Edit</div>
          </button>
        </div>
      </div>
      {deleteConfirmationOpened && (
        <Alert onDismiss={(event) => {
          event.stopPropagation()
          setDeleteConfirmationOpened(false)
        }}>
          <p className="text-center font-bold text-base text-neutral mb-5">
            Are you sure you want to delete this employer? <br/>This will delete the corresponding page.
          </p>
          <div className="flex gap-6 md:gap-0 justify-center items-center w-full">
            <PrimaryButton disabled={deleting} loading={deleting} onClick={(event) => {
              event.stopPropagation()
              setDeleting(true)
              const deleteCv = async (id: string) => {
                try {
                  const idToken = store.getState().user?.idToken
                  const accessToken = store.getState().user?.accessToken
                  const response = await instance.delete(
                    "cv",
                    {
                      params: { id: id },
                      headers: { "Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}` }
                    }
                  )
                  if (response.status === 204) onDelete()
                  setDeleting(false)
                } catch (err: any) {
                  onError ? onError(err) : handleError(err)
                  setDeleting(false)
                }
              }
              cv.id && deleteCv(cv.id)
            }}>
              <span className='md:w-36'>Yes</span>
            </PrimaryButton>
            <TertiaryButton onClick={(event) => {
              event.stopPropagation()
              setDeleteConfirmationOpened(false)
            }}>
              <span className='md:w-36'>No</span>
            </TertiaryButton>
          </div>
        </Alert>
      )}
    </div>
  )
}

export default CVCard