import cvEditorTypes from "./cvEditor.types"
import {
  ContentCVData,
  CVData,
  EducationData,
  EmployerData,
  EmploymentData,
  VisualsConfig
} from "../../components/VideoCvPageEditor/consts"

export const setCVs = (value: CVData[]) => ({ type: cvEditorTypes.SET_CVS, value })
export const setIsFirstTimeUser = (value: boolean) => ({ type: cvEditorTypes.SET_IS_FIRST_TIME_USER, value })
export const setCVContent = (value: ContentCVData) => ({ type: cvEditorTypes.SET_CV_CONTENT, value })
export const updateCVContent = (value: {data: any, fieldName: string}) => (
  { type: cvEditorTypes.UPDATE_CV_CONTENT, value }
)
export const removeEmployerAdaptation = (value: {index: number, fieldName: string}) => (
  {type: cvEditorTypes.REMOVE_EMPLOYER_ADAPTATION, value}
)
export const setEmployer = (value: {index: number, data: EmployerData}) => ({ type: cvEditorTypes.SET_EMPLOYER, value })
export const setEmployerContent = (value: {index: number, data: ContentCVData}) => (
  { type: cvEditorTypes.SET_EMPLOYER_CONTENT, value }
)
export const addEmployer = () => ({ type: cvEditorTypes.ADD_EMPLOYER })
export const addEmployment = (value: EmploymentData) => ({ type: cvEditorTypes.ADD_EMPLOYMENT, value })
export const removeEmployment = (index: number) => ({ type: cvEditorTypes.REMOVE_EMPLOYMENT, index })
export const setEmployment = (value: EmploymentData[]) => ({ type: cvEditorTypes.SET_EMPLOYMENT, value })
export const addEducation = (value: EducationData) => ({ type: cvEditorTypes.ADD_EDUCATION, value })
export const editEducation = (value: {index: number, data: EducationData}) => (
  { type: cvEditorTypes.EDIT_EDUCATION, value }
)
export const removeEducation = (index: number) => ({ type: cvEditorTypes.REMOVE_EDUCATION, index })
export const setEducation = (value: EducationData[]) => ({ type: cvEditorTypes.SET_EDUCATION, value })
export const editEmployer = (value: {index: number, data: EmploymentData}) => (
  { type: cvEditorTypes.EDIT_EMPLOYER, value }
)
export const removeEmployer = (value: {id?: string, index: number}) => ({ type: cvEditorTypes.REMOVE_EMPLOYER, value })
export const setVisualConfig = (value: VisualsConfig) => ({ type: cvEditorTypes.SET_VISUALS_CONFIG, value })
export const updateVisualsConfig = (value: {fieldName: string, data: string}) => (
  { type: cvEditorTypes.UPDATE_VISUALS_CONFIG, value }
)
export const setVideo = (value: {index: number, url: string}) => ({ type: cvEditorTypes.SET_VIDEO, value })
export const setPublished = (value: {index: number, data: boolean}) => ({ type: cvEditorTypes.SET_PUBLISHED, value })
export const setAllCVsPublished = () => ({ type: cvEditorTypes.SET_ALL_CVS_PUBLISHED })
export const setStepError = (value: string) => ({ type: cvEditorTypes.SET_STEP_ERROR, value })
export const clearStepError = () => ({ type: cvEditorTypes.CLEAR_STEP_ERROR })
export const setAlertMessage = (value: string) => ({ type: cvEditorTypes.SET_ALERT_MESSAGE, value })
export const clearAlertMessage = () => ({ type: cvEditorTypes.CLEAR_ALERT_MESSAGE })
export const setIsEducationalLogoVisible = (value: {isVisible: boolean, index?: number}) => (
  { type: cvEditorTypes.SET_IS_EDUCATIONAL_LOGO_VISIBLE, value }
)
export const setIsProfessionalLogoVisible = (value: {isVisible:boolean, index?:number}) => (
  { type: cvEditorTypes.SET_IS_PROFESSIONAL_LOGO_VISIBLE, value }
)
