import React, {useContext, useEffect, useState} from "react"
import {DataContext} from "../VideoCvPageEditor"
import Loom from "../../Loom/Loom"
import {setStepError, setVideo} from "../../../store/CvEditor/cvEditor.actions"
import {useDispatch, useSelector} from "react-redux"
import {CVData} from "../consts"
import PrimaryButton from "../../global/PrimaryButton/PrimaryButton"
import SecondaryButton from "../../global/SecondaryButton/SecondaryButton"
import PreviewGallery from "../PreviewGallery/PreviewGallery"

const VideoStep = () => {
  const { goToNextStep, publish } = useContext(DataContext)
  const CVs: CVData[] = useSelector((state: Storage) => state.cvEditor?.CVs)
  const currentEmployerInitialized = React.useRef(false)
  const dispatch = useDispatch()

  const [currentEmployer, setCurrentEmployer] = useState<number>(1)

  useEffect(() => {
    if (currentEmployerInitialized.current) return
    const videos: string[] = []
    CVs.forEach(cv => !cv.removed && cv.video && videos.push(cv.video))
    setCurrentEmployer(videos.length > 0 ?
      videos.length >= CVs.length ? videos.length : videos.length + 1 : 1
    )
    currentEmployerInitialized.current = true
  }, [CVs])

  const Video = ({videoId, index}: { videoId: string, index: number}) => {
    const [updateMode, setUpdateMode] = useState<boolean>(false)
    return (
      <>
        <div className="rounded-2xl block mb-10 overflow-hidden h-80">
          <iframe
            src={
              `https://www.loom.com/embed/${videoId.replace('https://www.loom.com/share/', '')}`
            }
            title={videoId}
            frameBorder="0"
            width="400"
            height="300"
            className="w-full h-full"
          />
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-6">
          {updateMode ?
            <Loom key={index} updateMode onVideoCreated={(videoUrl: string) => {
              dispatch(setVideo({index: index, url: videoUrl}))
              publish(true, false, true)
            }}/>
            :
            <button
              className='flex items-center justify-center bg-error border border-error text-center text-sm rounded-lg px-3 sm:px-6 h-11 text-white font-extrabold duration-300 transition-colors hover:border-error/80 hover:bg-error/80 active:scale-95'
              onClick={() => setUpdateMode(true)}
            >
              <img className="mr-3" src="/images/recordagain.svg" alt="icon" /> Record video again
            </button>
          }
          {CVs[currentEmployer - 1]?.video && CVs?.filter(cv => !cv.removed).length > 1 && (
              <PrimaryButton
                onClick={() => {setCurrentEmployer(currentEmployer >= CVs.length ? 1 : currentEmployer + 1)}}
              >
                <img className="mr-2.5" src="/images/nextwhite.svg" alt="next"/>Go to next employer 
              </PrimaryButton>
          )}
        </div>
      </>
    )
  }

  const RecordVideo = ({index, cv} : {index: number, cv: CVData}) => {
    if (cv?.removed) setCurrentEmployer(currentEmployer + 1)
    return (
      <div key={index} className="flex flex-col items-start">
        <div className="shadow-md flex items-center justify-center bg-white border border-gray-800 text-sm rounded-lg px-3 sm:px-4 h-10 font-bold duration-300 transition-colors mb-6">
          {CVs.filter(cv => !cv.removed).findIndex(data => data === cv) + 1}
          /{CVs.filter(cv => !cv.removed).length}: {cv?.employer?.company}
        </div>
        <Loom key={index} onVideoCreated={(videoUrl: string) => {
          dispatch(setVideo({index: index, url: videoUrl}))
          publish(true, false, true)
        }}/>
      </div>
    )
  }

  return (
    <div className="grid xl:grid-cols-12 gap-x-0 xl:gap-x-10">
      <div className="py-8 xl:py-12 xl:col-span-5">
        <h3 className="font-bold text-xl md:text-2xl mb-1">
          Your time to shine, why it’s YOU! 🤩 🎥 
        </h3>
        <p className="font-normal text-sm text-gray-500 mb-5">For each employer, you can record an unique video</p>
        <div className="flex items-center justify-between mb-5">
          {CVs[currentEmployer - 1]?.employer?.company && <div className="shadow-md flex items-center justify-center bg-white border border-gray-800 text-sm rounded-lg px-3 sm:px-4 h-10 font-bold duration-300 transition-colors">{CVs[currentEmployer - 1]?.employer?.company}</div>}
        </div>
        {CVs[currentEmployer - 1]?.video ?
          <Video
            videoId={CVs[currentEmployer - 1]?.video}
            index={currentEmployer - 1}
          /> :
          <RecordVideo cv={CVs[currentEmployer - 1]} index={currentEmployer - 1} />
        }
        <div className='mt-10 lg:mt-20 mb-10'>
          <SecondaryButton 
            onClick={() => {
              let areAllVideosRecorded = true
              CVs.filter(cv => !cv.removed).forEach(cv => {if (!cv.video) areAllVideosRecorded = false})
              areAllVideosRecorded ?
                goToNextStep() :
                dispatch(setStepError('Please record videos for all of your employers'))
            }}
          >
          Next</SecondaryButton>
          {/*<SecondaryButton onClick={() => {goToNextStep()}}>Skip validation</SecondaryButton>*/}
        </div>
      </div>
      <PreviewGallery
        onPrimarySlideChange={index => setCurrentEmployer(index + 1)}
        currentSlide={currentEmployer - 1}
      />
    </div>
  )
}

export default VideoStep