import {Menu, Transition} from "@headlessui/react"
import React, {Fragment} from 'react'
import {createCV, home, login, settings, support} from "../../../consts/links"

import SecondaryButton from "../../../components/global/SecondaryButton/SecondaryButton"
import {combineClassNames} from "../../../utils/functions"
import {fireAnalyticsEvent} from "../../../utils/analytics"
import {logout} from "../../../utils/firebase"
import {useNavigate} from "react-router-dom"
import {analyticsEvents} from "../../../consts/analyticsEvents"

const Header = ({ editorMode }: { editorMode?: boolean }) => {
  const navigate = useNavigate()
  const menuItems: { label: string, icon: string, destination: string, action?: () => void }[] = [
    { label: 'Dashboard', icon: '/images/rightmenuhome.svg', destination: home },
    { label: 'Account settings', icon: '/images/rightmenuaccountsettings.svg', destination: settings },
    { label: 'Support', icon: '/images/support.svg', destination: support },
    {
      label: 'Logout',
      icon: '/images/rightmenulogout.svg',
      destination: login,
      action: () => {
        logout()
        navigate(login)
      }
    }
  ]

  return (
    <header className="relative bg-white border-b border-gray-200 h-20 flex items-center">
      <div className="mx-auto max-w-screen-2xl px-3 md:px-5 flex-1">
        <div className="flex items-center justify-between py-3 lg:py-4">
          {editorMode ? (
            <div className='w-9 hidden md:block'>
              <button type='button' onClick={() => navigate(home)}>
                <img src="/images/smalllogo.svg" className='w-6' alt="logo" />
              </button>
            </div>
          ) : (
            <button type='button' onClick={() => navigate(home)}>
              <img src="/images/logofullNew.svg" className='w-44 lg:w-52' alt="logo" />
            </button>
          )}
          {editorMode && (
            <div className='flex items-center gap-x-4 font-extrabold text-xl flex-1 md:justify-center'>
              <img src="/images/editoricon.svg" className='w-7' alt="logo" />
              Video CV page editor
            </div>
          )}
          <div className='flex items-center gap-x-7'>
            {!editorMode && (
              <div className='hidden lg:block'>
                <SecondaryButton
                  onClick={() => {
                    navigate(createCV)
                    fireAnalyticsEvent(analyticsEvents.CREATE_CV)
                  }}
                >
                  Go to video CV page editor
                </SecondaryButton>
              </div>
            )}
            <Menu as="div" className="relative inline-block text-left">
              <div className='flex items-center'>
                <Menu.Button>
                  <img src="/images/rightmenicon.svg" className='w-9 h-9' alt="logo" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-lg focus:outline-none">
                  <div className="py-2">
                    {menuItems.map((item, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <button
                            onClick={() => item.action ? item.action() : navigate(item.destination)}
                            className={combineClassNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block w-full px-4 py-2 text-left text-sm font-bold duration-150'
                            )}
                          >
                            <div className="flex items-center gap-x-4">
                              <div className='w-7 flex justify-center'>
                                <img src={item.icon} alt="logo" />
                              </div>
                              {item.label}
                            </div>
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header