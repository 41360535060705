import { patterns } from "../consts/urlPatterns"

export const isValidURL = (input: string) => {
  if (input) {
    const pattern =
      patterns.PROTOCOL +
      patterns.DOMAIN +
      patterns.EXTENSION +
      patterns.IP_ADDRESS +
      patterns.LOCALHOST +
      patterns.PORT +
      patterns.PATH +
      patterns.QUERY_STRING +
      patterns.FRAGMENT_LOCATOR
      
    const regex = new RegExp(pattern)
    return regex.test(input)
  }
  return true
}
