import React, {useEffect} from 'react'
import AppWarning from "../components/global/InfoBar/AppWarning"

const CheckCompatibility = ({children} : {children: React.ReactElement}) => {
  useEffect(() => {
    window.location.host.split('.')[0] === 'my' &&
    window.location.replace(window.location.href.replace('my.', 'app.'))
  }, [])
  return (
    <>
      <AppWarning/>
      {children}
    </>
  )
}

export default CheckCompatibility