import React from 'react'

const Testimonials = () => {
    return (
        <div className="flex w-full flex-col max-w-sm px-4 md:px-0 lg:-mt-16 mb-20 lg:mb-0">
            <div className="flex justify-center mb-9">
                <div className="w-28 h-28 overflow-hidden rounded-full">
                    <img className="w-full h-full object-cover" src={testDesc.profile} alt="logo" />
                </div>
            </div>
            <p className='font-bold text-base md:text-lg text-neutral'>{testDesc.description}</p>
            <p className="italic mt-6 font-bold text-right text-base md:text-lg text-neutral">
                {testDesc.subName}
            </p>
        </div>
    )
}

const testDesc = {
    subName: "Esther, Candidate from New York",
    profile: "/images/profile.png",
    description: `"My CV landed on top of the (digital) pile! Thank you VideoCVpage!"`
};

export default Testimonials