import React from 'react'

const TertiaryButton = ({children, id, onClick} : {children: any, id?: string, onClick: (event: any) => void}) => (
  <button
    id={id}
    className="flex items-center justify-center text-sm h-8 text-primary font-semibold active:scale-95 gap-x-2 underline"
    onClick={onClick}
  >
    {children}
  </button>
)

export default TertiaryButton