import React, {useContext} from "react"
import {DataContext} from "../VideoCvPageEditor"
import SecondaryButton from "../../global/SecondaryButton/SecondaryButton"

const TemplateStep = () => {
  const { goToNextStep } = useContext(DataContext)
  return (
    <div className="py-8 lg:py-12">
      <div className="grid xl:grid-cols-12 gap-x-0 gap-y-10 xl:gap-x-20 flex-1">
        <div className="xl:col-span-5 w-full flex flex-col items-center">
          <h3 className="font-bold text-sm mb-5">Template 1️⃣</h3>
          <div className="w-full border border-secondary shadow-login">
            <img src="/images/template.png" alt="template" />
          </div>
        </div>
        <div className="xl:col-span-5 xl:mt-10">
          <SecondaryButton onClick={goToNextStep}>Next</SecondaryButton>
          <p className="mt-4 text-gray-500">There’s only one template for now. <br /> We’ll add more templates soon:)</p>
        </div>
      </div>
    </div>
  )
}

export default TemplateStep