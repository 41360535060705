import React from 'react'
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"

const SecondaryButton = ({children, id, onClick, disabled, loading} : {
  children: any, id?: string, onClick: (event: any) => void, disabled?: boolean, loading?: boolean
}) => (
  <button
    id={id}
    className={`${loading ? 'pl-11 pr-6' : 'px-6'} flex items-center justify-center bg-secondary border border-secondary text-sm rounded-xl h-11 text-white font-extrabold duration-300 transition-colors hover:bg-secondary/80 hover:border-secondary/80 min-w-[200px] active:scale-95`}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ?
      <div className="flex" style={{marginLeft: '-24px'}}>
        <LoadingSpinner small/> Loading
      </div> :
      children
    }
  </button>
)

export default SecondaryButton