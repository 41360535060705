import React from "react";

const SupportCommunity = ({ email }: { email?: string }) => {
  return (
    <div>
      <p className="font-semibold  text-sm">
        This page has been deleted. Please&nbsp;
        <a
          className="underline"
          href=" https://www.videocvpage.com/contact"
          target="blank"
        >
          contact us
        </a>{" "}
        for more info regarding this application
      </p>
    </div>
  );
};

export default SupportCommunity;
