export enum patterns {
  PROTOCOL = "^(https?:\\/\\/)?",
  DOMAIN = "((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+",
  EXTENSION = "[a-zA-Z]{2,13})",
  IP_ADDRESS = "|((\\d{1,3}\\.){3}\\d{1,3})",
  LOCALHOST = "|localhost)",
  PORT = "(\\:\\d{1,5})?",
  PATH = "(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*",
  QUERY_STRING = "(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?",
  FRAGMENT_LOCATOR = "(\\#[-a-zA-Z&\\d_]*)?$",
}
