import React, {useState} from 'react'
import TertiaryButton from "../../global/TertiaryButton/TertiaryButton"
import { useSelector } from "react-redux"
import Error from "../../global/Error/Error"
import store from "../../../store/createStore"
import {cvEditorStorage} from "../consts"
import { isValidURL } from '../../../middleware/isValidURL'

const TimelineInput = (
  {editorField, sectionLabel, submitButtonLabel, inputLabels, fields, initialValue, remove, edit, submit, toggleVisibility}:
  {
    editorField: string,
    sectionLabel: string,
    submitButtonLabel: string,
    inputLabels: string[],
    fields: string[],
    initialValue: any,
    remove: (data: any) => void,
    edit: ({index, data} : {index: number, data: any}) => void
    submit: (data: any) => void,
    toggleVisibility: (isVisible: boolean, index?: number) => void,
  }
) => {
  const [editing, setEditing] = useState<number|null>(null)
  const [formOpened, setFormOpened] = useState<boolean>(false)

  const showProfessionalLogo: boolean = useSelector(
    (state: Storage) => state.cvEditor?.CVs[0].showProfessionalLogo
  )
  const showEducationalLogo: boolean = useSelector(
    (state: Storage) => state.cvEditor?.CVs[0].showEducationalLogo
  )
  const timeline = useSelector(
    (state: Storage) => state.cvEditor?.[editorField]
  )

  const Form = () => {
    const [data, setData] = useState<{ [key: string]: string }>(
      store.getState().cvEditor?.[editorField as keyof cvEditorStorage]?.[editing as number] || initialValue
    )
    const [errors, setErrors] = useState<{ [inputName: string]: string }>({})

    const handleChange = (event: any, fieldName: string) => {
      setErrors({...errors, [fieldName]: '', global: ''})
      setData({
        ...data,
        [fieldName]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      })
    }

    const inputs: {
      label: string, name: string, type: string, charactersLimit?: number, fullWidth?: boolean, placeholder?: string
    }[] = [
      {label: inputLabels[0], name: fields[0], type: 'text', charactersLimit: 100, placeholder: 'Enter here'},
      {label: inputLabels[1], name: fields[1], type: 'text', charactersLimit: 100, placeholder: 'Enter here'},
      {label: 'Start & end date', name: 'dateSelector', type: 'dateSelector', fullWidth: true},
      {label: 'City', name: 'city', type: 'text', charactersLimit: 100, fullWidth: true, placeholder: 'Enter here'},
      {
        label: 'Short description',
        name: 'shortDescription',
        type: 'textarea',
        charactersLimit: 300,
        fullWidth: true,
        placeholder: 'Enter here'
      },
      {label: 'Website', name: 'website', type: 'text', charactersLimit: 100, fullWidth: true, placeholder: 'Please enter as: www.example.com'},
    ]

    return (
      <div className='bg-white p-5 border border-yellow-400 rounded-lg grid grid-cols-2 gap-5'>
        {inputs.map((input, index) => (
          <div className={input.fullWidth ? 'col-span-2 relative' : ''} key={index}>
            <label className='font-bold mb-1 block text-sm' htmlFor={input.name}>{input.label}</label>
            {input.type === 'textarea' && (
              <textarea
                id={input.name}
                className="bg-white border border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 pl-4 pr-14 focus:outline-none"
                value={data[input.name]}
                onChange={event => {
                  if (input.charactersLimit && event.target.value.length > input.charactersLimit) return
                  handleChange(event, input.name)
                }}
                placeholder={input.placeholder}
              />
            )}
            {input.type === 'text' && (
              <input
                type="text"
                id={input.name}
                className="bg-white border border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                value={data[input.name]}
                onChange={event => {
                  if (input.charactersLimit && event.target.value.length > input.charactersLimit) return
                  handleChange(event, input.name)
                }}
                placeholder={input.placeholder}
              />
            )}
            {input.type === 'dateSelector' && (
              <div className='col-span-2'>
                <div className='grid grid-cols-2 gap-5'>
                  <div>
                    <input
                      type="date"
                      id="startDate"
                      className="bg-white border border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                      value={data.startDate}
                      onChange={event => handleChange(event, 'startDate')}
                    />
                    <Error errorMessage={errors?.startDate || ''}/>
                  </div>
                  <div className='relative'>
                    {
                      !data?.present && (
                        <input
                          type="date"
                          id="endDate"
                          className="bg-white border border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 px-4 focus:outline-none"
                          value={data.endDate}
                          onChange={event => handleChange(event, 'endDate')}
                        />
                      )
                    }
                    <label className="inline-flex bg-transparent relative top-2.5 items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value={data.present}
                        checked={!!data.present}
                        id="present"
                        className="sr-only peer"
                        onChange={event => handleChange(event, 'present')}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  dark:peer-focus:ring-gray-800 rounded-full peer dark:bg-gray-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gray-800"/>
                      <span className="ml-2 text-xs font-medium">Present</span>
                    </label>
                    <Error errorMessage={errors?.endDate}/>
                  </div>
                </div>
              </div>
            )}
            {input.charactersLimit && input.type === 'textarea' && (
              <div className='text-xs absolute top-8 right-4'>{data[input.name].length}/{input.charactersLimit}</div>
            )}
            <Error errorMessage={errors?.[input.name]}/>
          </div>
        ))}
        <div className='flex items-center gap-x-5'>
          <TertiaryButton onClick={() => {
            const errors: { [inputName: string]: string } = {}
            const optionalFields: string[] = ['city', 'shortDescription', 'present', 'website']
            Object.entries(data).forEach((input) => {
              const inputName = input[0]
              const inputValue = input[1]
              if (
                (!inputValue || inputValue.length <= 0) &&
                !optionalFields.includes(inputName) &&
                !(inputName === 'endDate' && data.present)
              ) errors[inputName] = 'The field is required'
              else if (
                (inputName === 'startDate' || inputName === 'endDate') &&
                !inputValue.match(/^\d{4}-\d{2}-\d{2}$/) &&
                !(inputName === 'endDate' && data.present)
              ) {
                errors[inputName] = 'Date is invalid!'
              }
              else if (inputName === 'website' && !isValidURL(inputValue)) {
                errors[inputName] = 'URL invalid!'
              }
            })
            if (Object.entries(errors).length) {
              setErrors({...errors, global: 'Missing inputs'})
            } else {
              setErrors({})
              editing === null ? submit(data) : edit({index: editing, data: data})
              setEditing(null)
              setFormOpened(false)
            }
          }}>
            Save
          </TertiaryButton>
          <TertiaryButton onClick={() => {
            setEditing(null)
            setFormOpened(false)
          }}>Cancel</TertiaryButton>
          <Error errorMessage={errors?.global || ''}/>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full bg-pale-100 pt-5 pb-7 px-5 rounded-xl mb-7 relative">
      <h2 className="w-full text-center block font-bold mb-6">{sectionLabel}</h2>
      <div className="flex items-center absolute right-5 ">
        <input
          type="checkbox"
          checked={
            editorField === "professionalTimeline"
              ? showProfessionalLogo
              : showEducationalLogo
          }
          className="w-4 h-4 accent-gray-800 cursor-pointer"
          onChange={() =>
            toggleVisibility(
              editorField === "professionalTimeline"
                ? !showProfessionalLogo
                : !showEducationalLogo
            )
          }
        />
        <label
          htmlFor="logo-vsibilty"
          className="ml-2 text-sm leading-7 font-bold flex cursor-pointer flex-1"
        >
          Show logos
        </label>
      </div>
      {editing === null && timeline.map((item: any, index: number) => (
        <div
          className="flex justify-between items-start bg-white p-5 border border-yellow-400 rounded-lg relative mb-8"
          key={index}
        >
          <div>
            <h4 className='font-bold text-sm mb-1'>{item[fields[1]]} - {item[fields[0]]}</h4>
            <p className='font-semibold text-sm'>{item.startDate} - {item.endDate}</p>
          </div>
          <button onClick={() => {
            setFormOpened(true)
            setEditing(index)
          }}>
            <img src="/images/dropdownarrow.svg" alt="edit"/>
          </button>
          <button
            type='button'
            onClick={() => remove(index)}
            className='absolute -right-2 -top-2 w-5 h-5 bg-white border-[1.5px] rounded-full flex items-center justify-center border-neutral font-semibold text-md duration-200 hover:bg-neutral hover:text-white'
          >
            &#x2715;
          </button>
        </div>
      ))}
      {
        formOpened ?
          <Form/> :
          <div>
            <TertiaryButton onClick={() => setFormOpened(true)}>
              <img src="/images/add.svg" className='rotate-45' alt="add"/>{submitButtonLabel}
            </TertiaryButton>
          </div>
      }
    </div>
  )
}

export default TimelineInput
