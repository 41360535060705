import { useCallback, useEffect, useState } from "react";

import { detectIncognito } from "detectincognitojs";
import store from "../store/createStore";
import { toggleWarning } from "../store/app/app.action";
import { useSelector } from "react-redux";

export function useWarningVisible(): boolean {
    return useSelector((state: any) => state.app.warningShow);
  }
  
  export function useWarningToggle(): () => void {
    return useCallback(() => store.dispatch(toggleWarning()), []);
  }
  
export const useCheckBrowser = () => {

    const [isPrivate, setBrowser] = useState<boolean>(false);
    useEffect(() => {
        detectIncognito()
        .then((result) => setBrowser(result.isPrivate))
        .catch((err)=>{console.log(err)})      
    }, []);
    return isPrivate;
};

export const useCheckDevice = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleScreenChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleScreenChange);
    return () => {
      window.removeEventListener("resize", handleScreenChange);
    };
  }, []);
  return width <= 768;
};

