const cvEditorTypes = {
   SET_CVS: "SET_CVS",
   SET_IS_FIRST_TIME_USER: "SET_IS_FIRST_TIME_USER",
   SET_CV_CONTENT: "SET_CV_CONTENT",
   UPDATE_CV_CONTENT: "UPDATE_CV_CONTENT",
   REMOVE_EMPLOYER_ADAPTATION: "REMOVE_EMPLOYER_ADAPTATION",
   SET_EMPLOYER: "SET_EMPLOYER",
   ADD_EMPLOYER: "ADD_EMPLOYER",
   ADD_EMPLOYMENT: "ADD_EMPLOYMENT",
   REMOVE_EMPLOYMENT: "REMOVE_EMPLOYMENT",
   SET_EMPLOYMENT: "SET_EMPLOYMENT",
   ADD_EDUCATION: "ADD_EDUCATION",
   EDIT_EDUCATION: "EDIT_EDUCATION",
   REMOVE_EDUCATION: "REMOVE_EDUCATION",
   SET_EDUCATION: "SET_EDUCATION",
   EDIT_EMPLOYER: "EDIT_EMPLOYER",
   REMOVE_EMPLOYER: "REMOVE_EMPLOYER",
   SET_VISUALS_CONFIG: "SET_VISUALS_CONFIG",
   UPDATE_VISUALS_CONFIG: "UPDATE_VISUALS_CONFIG",
   SET_VIDEO: "SET_VIDEO",
   SET_CONTENT: "SET_CONTENT",
   SET_EMPLOYER_CONTENT: "SET_EMPLOYER_CONTENT",
   SET_PUBLISHED: "SET_PUBLISHED",
   SET_ALL_CVS_PUBLISHED: "SET_ALL_CVS_PUBLISHED",
   SET_STEP_ERROR: "SET_STEP_ERROR",
   CLEAR_STEP_ERROR: "CLEAR_STEP_ERROR",
   SET_ALERT_MESSAGE: "SET_ALERT_MESSAGE",
   CLEAR_ALERT_MESSAGE: "CLEAR_ALERT_MESSAGE",
   SET_IS_EDUCATIONAL_LOGO_VISIBLE: "SET_IS_EDUCATIONAL_LOGO_VISIBLE",
   SET_IS_PROFESSIONAL_LOGO_VISIBLE: "SET_IS_PROFESSIONAL_LOGO_VISIBLE",
}

export default cvEditorTypes