import React, { useState } from "react"
import {login, privacyPolicyUrl, register} from "../../consts/links"
import { registerWithEmailAndPassword, signInWithGoogle } from "../../utils/firebase"

import { Link } from "react-router-dom"
import GoogleButton from "../global/GoogleButton/GoogleButton"
import Testimonials from "../../components/Testimonials/Testimonials"
import SecondaryButton from "../global/SecondaryButton/SecondaryButton"

function Register() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [name, setName] = useState("")
  const [loading, setLoading] = useState<{ provider: string } | null>(null)
  const [error, setError] = useState<string | null>(null)

  return (
    <div
      className="flex flex-col lg:flex-row items-center justify-center md:min-h-screen antialiased gap-10 lg:gap-20 xl:gap-32 2xl:gap-40">
      <div className="flex w-full flex-col max-w-md mt-12 md:mt-0 px-4 md:px-0">
        <div className="flex justify-center mb-8">
          <Link to={register}>
            <img width="213" height="41" src="/images/logofullNew.svg" alt="logo" />
          </Link>
        </div>
        <div className="flex-shrink-0 w-full shadow-login bg-primary rounded-2xl">
          <div className="pt-7 px-5 md:px-10 pb-11">
            <div className="mb-10">
              <h1
                className="text-2xl leading-8 font-bold text-center text-white">Publish CV pages for free</h1>
            </div>
            <div className="flex flex-col mb-3">
              <input
                type="text"
                className="bg-white border-2 border-white text-sm rounded-xl block w-full py-2.5 px-4 focus:outline-none font-bold text-neutral placeholder:text-base-400"
                value={name}
                onChange={(e) => {
                  setError(null)
                  setName(e.target.value)
                }}
                placeholder="First name"
                required />
            </div>
            <div className="flex flex-col mb-3">
              <input
                type="text"
                className="bg-white border-2 border-white text-sm rounded-xl block w-full py-2.5 px-4 focus:outline-none font-bold text-neutral placeholder:text-base-400"
                value={email}
                onChange={(e) => {
                  setError(null)
                  setEmail(e.target.value)
                }}
                placeholder="Email address"
                required />
            </div>
            <div className="flex flex-col mb-5">
              <input
                type="password"
                className="bg-white border-2 border-white text-sm rounded-xl block w-full py-2.5 px-4 focus:outline-none font-bold text-neutral placeholder:text-base-400"
                value={password}
                onChange={(e) => {
                  setError(null)
                  setPassword(e.target.value)
                }}
                placeholder="Password"
                required />
            </div>
            <div className="flex flex-col mb-5">
              <SecondaryButton
                onClick={() => {
                  if (!name || !email || !password) return setError('All fields are required')
                  setLoading({ provider: 'email' })
                  registerWithEmailAndPassword(name, email, password)
                    .catch((err) => {
                      setLoading(null)
                      setError(err?.toString())
                    })
                }}
                disabled={!!(loading)}
                loading={loading?.provider === 'email'}
              >Create an account
              </SecondaryButton>
            </div>
            <div className="flex flex-col mb-2.5">
              <GoogleButton
                onClick={() => {
                  setLoading({ provider: 'google' })
                  signInWithGoogle(true)
                    .catch((err) => {
                      setLoading(null)
                      setError(err?.toString())
                    })
                }}
                disabled={!!(loading)}
                loading={loading?.provider === 'google'}
              >
                <img src="/images/google-logo.svg" className="w-4 h-4 mr-3" alt="google"/>
                Register with Google
              </GoogleButton>
              {error && <p className="text-error bg-white rounded-md p-1 text-sm mt-3 text-center w-full">{error}</p>}
            </div>
            <div className="flex flex-col md:flex-row items-center mb-10 text-sm font-bold justify-center text-white">
              By signing up you agree with the
              <a className="underline ml-1" href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">terms & privacy policy</a>
            </div>
            <div className="flex items-center font-semibold text-sm justify-center text-white">
              Do you have an account?
              <Link className="underline ml-1" to={login}>Login here</Link>
            </div>
          </div>
        </div>
      </div>
      <Testimonials />

    </div>
  )
}

export default Register
