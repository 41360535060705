import React, { forwardRef } from "react"

interface timelineProps{
  sectionLabel: string
  timelineItems: any[]
  isLogoVisible: boolean
  listWrapper?: boolean
  preview?: boolean
}
const Timeline = forwardRef<HTMLDivElement, timelineProps>(
  ({sectionLabel, timelineItems,isLogoVisible,listWrapper,preview}, ref) => {
  const formatDateDisplay = (date: string) => date.length > 7 ? date.substring(0, 7) : date

  return (
    <div ref={ref} id="cvBody" className="cv-body">
      <div className="cv-body-item">
        <h4>{sectionLabel}</h4>
        <div className="cv-body-content">
          <ol className={listWrapper ? "border-l border-gray-300 flex flex-col gap-y-10" : ""}>
            {timelineItems.map((timeline, index) => (
              <li key={index}>
                <div className="cv-body-item-header">
                  <div className="dot"/>
                  <p className="flex flex-row items-center">
                    {timeline.jobTitle || timeline.degreeTitle} -&nbsp;
                    <a
                      className={timeline.website ? `text-blue-500 underline` : "pointer-events-none"}
                      href={
                        /^https:\/\//.test(timeline.website as string) ?
                          `${timeline.website}` : `https://${timeline.website}`
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {timeline.employer || timeline.institution}
                    </a>
                    {isLogoVisible && timeline.website && (
                      <img
                        className={`ml-2 ${preview ? "w-3 h-3" : "w-5 h-5"}`}
                        src={`https://www.google.com/s2/favicons?domain=${timeline.website}&sz=64`}
                        alt="icn"
                      />
                    )}
                  </p>
                </div>
                <div className="cv-body-item-body">
                  <h6>{formatDateDisplay(timeline.startDate)} - {timeline.present? "Present" :  formatDateDisplay(timeline.endDate)}</h6>
                  <p>{timeline.shortDescription}</p>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  )
})

export default Timeline
