import { CVData } from "../components/VideoCvPageEditor/consts"
import { logout } from "./firebase"
import { login } from "../consts/links"

export const loadVariables = (text: string, cv: CVData) => {
  let output = text
  const findVariable = () => {
    if (!output?.includes('{{') || !output?.includes('}}')) return null
    return output.substring(output.indexOf('{{') + 2, output.indexOf('}}', output.indexOf('{{') + 2))
  }
  const getValue = (valueToGet: string) => {
    const keys: string[] = valueToGet.split('.')
    let keyValue: any
    keys.forEach(key => { keyValue = keyValue ? keyValue[key as keyof CVData] : cv[key as keyof CVData] })
    const value: string = keyValue?.toString()
    output = output.replace(`{{${valueToGet}}}`, value)
    const variable: string | null = findVariable()
    variable && getValue(variable)
  }
  const variable: string | null = findVariable()
  variable && getValue(variable)
  return output
}

export const combineClassNames = (...classes: string[]) => { return classes.filter(Boolean).join(' ') }

export const areCVsUpdated = (initialState: CVData[], currentState: CVData[]) => {
  const initialValue = [...initialState]
  const currentValue = [...currentState]
  currentValue.forEach((cv, index) => {
    if (initialValue[index]) initialValue[index].published = cv.published
  })
  return currentValue.filter(cv => !cv.completed).length ?
    true : JSON.stringify(initialValue) !== JSON.stringify(currentValue)
}

export const handleError = (error: any, preventQuitDisable?: () => void) => {
  if (error?.response?.status === 401) {
    const isSessionExpired = error?.response?.data?.error === "Session expired"
    logout()
    isSessionExpired && preventQuitDisable && preventQuitDisable()
    window.location.href = isSessionExpired ? `${login}?session-expired=1` : login
  }
}

export const handleAuthErrors = (errorCode: string) => {
  switch (errorCode) {
    case "auth/invalid-password": return "Password is incorrect"
    case "auth/invalid-email": return "Email is not valid"
    case "auth/wrong-password": return "Your password is wrong"
    case "auth/user-not-found": return "User with this email doesn't exist"
    case "auth/email-already-in-use": return "This email is already in use"
    case "auth/weak-password": return "Password should be at least 6 characters"
    default: return "Something went wrong!"
  }
}