import React, {useEffect, useState} from 'react'
import PrimaryLayout from "../../layouts/PrimaryLayout/PrimaryLayout"
import Onboarding from "../Onboarding/Onboarding"
import {CVData} from "../VideoCvPageEditor/consts"
import {instance} from "../../utils/axios"
import CVCard from "../VideoCvPageEditor/CVCard/CVCard"
import {createCV} from "../../consts/links"
import {useNavigate} from "react-router-dom"
import store from "../../store/createStore"
import {handleError} from "../../utils/functions"
import Loading from "../../layouts/Loading/Loading"
import TertiaryButton from "../global/TertiaryButton/TertiaryButton"

const Home = () => {

  const [CVs, setCVs] = useState<CVData[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const getUserCVs = async () => {
      try {
        const idToken = store.getState().user?.idToken
        const accessToken = store.getState().user?.accessToken
        const response: { data: CVData[] } = await instance.get(
          "cv/load",
          {headers: {"Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}`}}
        )
        setCVs([...response.data])
        setLoading(false)
      } catch (err: any) {
        handleError(err)
        setLoading(false)
      }
    }
    getUserCVs()
  }, [navigate])

  return (
    loading ?
      <Loading/> :
      <PrimaryLayout>
        {CVs.length ?
          <div className='py-6'>
            <div className='flex flex-col'>
              <h3 className='text-lg md:text-xl font-extrabold mb-2.5'>👉 Your video CV pages</h3>
              <p className='text-sm font-semibold'>Clicking “Edit” will take you back to the editor</p>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-11 mt-8'>
              {CVs.map((cv, index) => cv.id && cv.completed && (
                <CVCard
                  key={cv.id}
                  cv={cv}
                  index={index}
                  onDelete={() => setCVs(CVs.filter(cvItem => cvItem.id !== cv.id))}
                  onEdit={() => navigate(createCV)}
                />
              ))}
              <div className="flex justify-center items-center pb-8">
                <TertiaryButton onClick={() => navigate(createCV)}>
                  <img src="/images/add.svg" className='rotate-45' alt="add"/> Add more employers
                </TertiaryButton>
              </div>
            </div>
          </div> :
          <Onboarding/>
        }
      </PrimaryLayout>
  )
}

export default Home