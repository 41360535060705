const userTypes = {
  SET_ID_TOKEN: "SET_ID_TOKEN",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  DESTROY_ID_TOKEN: "DESTROY_ID_TOKEN",
  DESTROY_ACCESS_TOKEN: "DESTROY_ACCESS_TOKEN",
  ADD_USER_DATA: "ADD_USER_DATA",
  REMOVE_USER_DATA: "REMOVE_USER_DATA",
  ADD_USER_DATA_UPDATE: "ADD_USER_DATA_UPDATE",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
}

export default userTypes