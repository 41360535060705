import React from "react"
import { useSelector } from "react-redux"
import { ContentCVData, CVData, EducationData, EmploymentData } from "../consts"
import { loadVariables } from "../../../utils/functions"
import Timeline from "../../Cv/Timeline"

const PreviewCV = ({ index, thumbnail }: { index: number, thumbnail?: boolean }) => {
  const cv: CVData = useSelector((state: Storage) => state.cvEditor.CVs?.[index])
  const cvContent: ContentCVData = useSelector((state: Storage) => state.cvEditor.cvContent)
  const professionalTimeline: EmploymentData[] = useSelector(
    (state: Storage) => state.cvEditor.professionalTimeline
  )
  const education: EducationData[] = useSelector((state: Storage) => state.cvEditor.education)

  if (!cv) return null

  return (
    <div className="relative">
      <button type="button" className="absolute z-20 shadow-md -top-2 -left-2 xl:left-2 flex items-center justify-center bg-white border border-gray-800 text-sm rounded-lg px-3 sm:px-4 h-10 font-bold duration-300 transition-colors active:scale-95">
        {loadVariables(cv.employer.company, cv) || 'Company Name'}
      </button>
      <div className={`font-${cv.visualsConfig.font} theme-${cv.visualsConfig.colourScheme} cv-template cv-preview`}>
        <div className="cv-header">
          <div className="cv-header-text">
            <h2>{loadVariables(cv.content.titleCustom || cv.content.title, cv) || 'Company Name'}</h2>
            <h4>
              {
                loadVariables(cv.content.nameCustom || cv.content.name, cv) ||
                'Your name will go here'
              }
            </h4>
            
            <p>
              {
                loadVariables(cv.content.shortSummaryCustom || cv.content.shortSummary, cv) ||
                'Here will be a short summary you can customize for each prospective employer'
              }
            </p>
            <button onClick={() => cv.id && window.open(cv.video, '_blank')}>Watch Video</button>
          </div>
          <div className='cv-header-video'>
            {cv.video ? (
              <iframe
                src={
                  `https://www.loom.com/embed/${cv.video?.replace('https://www.loom.com/share/', '')}`
                }
                title={cv.video}
                frameBorder="0"
                width="400"
                height="300"
                className="w-full h-44"
              />
            ) : (
              <div
                className="text-sm font-bold border border-dashed rounded-xl border-gray-400 h-44 flex items-center justify-center text-center px-5 bg-white">
                <span className="w-44">Your personal video for each prospective employer will go here</span>
              </div>
            )}
          </div>
          <button className='video-btn-xs' onClick={() => cv.id && window.open(cv.video, '_blank')}>Watch Video</button>
        </div>
        <Timeline
          sectionLabel='Professional timeline'
          timelineItems={professionalTimeline}
          isLogoVisible={cv.showProfessionalLogo}
          preview
        />
        <Timeline
          sectionLabel='Educational timeline'
          timelineItems={education}
          isLogoVisible={cv.showEducationalLogo}
          preview
        />
        <div className="cv-footer">
          <div className="cv-footer-content">
            {cvContent.email && (
              <div className='cv-footer-unit'>
                <h5>Email</h5>
                <a href={`mailto:${cvContent.email}`} target="_blank" rel="noreferrer">
                  <span>{cvContent.email}</span><img src="/images/linkicon.svg" alt="icon" />
                </a>
              </div>
            )}
            {cvContent.phone && (
              <div className='cv-footer-unit'>
                <h5>Phone Number</h5>
                <a href={`tel:${cvContent.phone}`} target="_blank" rel="noreferrer">
                  <span>{cvContent.phone}</span><img src="/images/linkicon.svg" alt="icon" />
                </a>
              </div>
            )}
            {cvContent.linkedIn && (
              <div className='cv-footer-unit'>
                <h5>Linkedin</h5>
                <a
                  href={
                    cv.content.linkedIn.substring(0,8) === "https://" ?
                      cv.content.linkedIn :
                      "https://" + cv.content.linkedIn
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{cvContent.linkedIn}</span><img src="/images/linkicon.svg" alt="icon" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewCV