import React, {useEffect, useState} from 'react'
import {ContentCVData, CVData} from "../consts"
import {useDispatch, useSelector} from "react-redux"
import {
  clearStepError,
  setEmployerContent,
  updateCVContent
} from "../../../store/CvEditor/cvEditor.actions"
import DropdownButton from "./DropdownButton"
import Error from "../../global/Error/Error"
import store from "../../../store/createStore"

const Input = (
  {
    label,
    name,
    placeholder,
    error,
    charactersLimit,
    allowVariables,
    textarea,
    customizeByEmployer,
    onEmployerSelect,
    cvOnFocusIndex
  } :
  {
    label: string,
    name: string,
    placeholder?: string,
    error?: string,
    charactersLimit?: number,
    allowVariables?: boolean,
    textarea?: boolean,
    customizeByEmployer?: boolean,
    onEmployerSelect?: (index: number) => void,
    cvOnFocusIndex?: number
  }
) => {
  const dispatch = useDispatch()
  const CVs: CVData[] = useSelector((state: Storage) => state.cvEditor?.CVs)
  const CVContent: ContentCVData = useSelector((state: Storage) => state.cvEditor?.cvContent)
  const [adapting, setAdapting] = useState<{index: number, company: string, update: (value: any) => void } | null>(
    cvOnFocusIndex && CVs[cvOnFocusIndex].content[name + 'Custom' as keyof ContentCVData] ? {
      index: cvOnFocusIndex,
      company: CVs[cvOnFocusIndex].employer.company,
      update: (value: any) => {
        if (charactersLimit && value.length > charactersLimit) return
        dispatch(setEmployerContent({
          index: cvOnFocusIndex, data: {...CVs[cvOnFocusIndex].content, [name + 'Custom']: value}
        }))
      }
    } : null
  )
  const [errorMessage, setErrorMessage] = useState(error)
  const value = CVContent[name as keyof ContentCVData]
  const handleUpdate = (value: string) => {
    setErrorMessage('')
    dispatch(clearStepError())
    if (charactersLimit && value.length > charactersLimit) return
    dispatch(updateCVContent({fieldName: name, data: value}))
  }

  useEffect(() => {
    const currentCVs: CVData[] = store.getState().cvEditor.CVs
    if (cvOnFocusIndex || cvOnFocusIndex === 0) {
      setAdapting({
        index: cvOnFocusIndex,
        company: currentCVs[cvOnFocusIndex].employer.company,
        update: (value: any) => {
          if (charactersLimit && value.length > charactersLimit) return
          dispatch(setEmployerContent({
            index: cvOnFocusIndex, data: {...currentCVs[cvOnFocusIndex].content, [name + 'Custom']: value}
          }))
        }
      })
    }
  }, [cvOnFocusIndex, charactersLimit, name, dispatch])
  useEffect(() => setErrorMessage(error), [error])

  const CustomizeByEmployer = () => {
    const buttons: {label: string, action: () => void}[] = [
      {label: 'General', action: () => setAdapting(null)}
    ]

    const customizingEmployersIndexes: number[] = []
    CVs.forEach((cv, index) => {
      cv.content?.[name + 'Custom' as keyof ContentCVData] && customizingEmployersIndexes.push(index)
    })

    CVs.forEach((cv, index) => buttons.push(
      {
        label: cv.employer.company,
        action: () => {
          onEmployerSelect && onEmployerSelect(index)
          setAdapting({
            index: index,
            company: cv.employer.company,
            update: (value: any) => {
              if (charactersLimit && value.length > charactersLimit) return
              dispatch(setEmployerContent({
                index: index, data: {...CVs[index].content, [name + 'Custom']: value}
              }))
            }
          })
        }
      }
    ))
    return <DropdownButton label='Adapt per employer' buttons={buttons}/>
  }

  return (
    <div className='relative'>
      <label className='w-full text-center block font-bold mb-6' htmlFor={name}>{label} {adapting && adapting.company}</label> 
      {textarea ? (
        <textarea
          id={name}
          className="bg-white border h-24 border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 pl-4 pr-14 focus:outline-none"
          value={adapting ? CVs[adapting.index].content?.[`${name}Custom` as keyof ContentCVData] || '' : value || ''}
          onChange={event => adapting ? adapting.update(event.target.value) : handleUpdate(event.target.value)}
          placeholder={placeholder}
        />
      ) : (
        <input
          type="text"
          id={name}
          className="bg-white border border-yellow-400 sm:text-sm rounded-lg block w-full py-2.5 px-4 focus:outline-none"
          value={adapting ? CVs[adapting.index].content?.[`${name}Custom` as keyof ContentCVData] || '' : value || ''}
          onChange={event => adapting ? adapting.update(event.target.value) : handleUpdate(event.target.value)}
          placeholder={placeholder}
        />
      )}
      {charactersLimit && (
        <div className='text-xs absolute top-14 right-4'>
          {adapting ?
            CVs[adapting.index].content?.[`${name}Custom` as keyof ContentCVData]?.length || 0 :
            value?.length || 0
          }/{charactersLimit}
        </div>
      )}
      {allowVariables && (
        <div className='mt-2.5'>
          <DropdownButton label='Insert personalization' buttons={[
            {label: 'Prospective employer name', action: () => handleUpdate(value + ' {{employer.company}}')}
          ]}/>
        </div>
      )}
      <Error errorMessage={errorMessage || ''}/>
      <div className='mt-2.5'>
        {customizeByEmployer && <CustomizeByEmployer/>}
      </div>
    </div>
  )
}

export default Input