import React from "react"

const Alert = ({children, onDismiss} : {children: any, onDismiss: (event?: any) => void}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-5 overflow-auto overscroll-contain">
      <div className="fixed inset-0 bg-neutral/50" onClick={onDismiss}></div>
      <div className="bg-white relative z-20 px-4 py-6 md:p-11 rounded-2xl border border-pale-200 max-w-full my-auto">
        {children}
        <button onClick={onDismiss} className='absolute -right-4 -top-4 active:scale-95'>
          <img src="/images/remove.svg" alt="remove" />
        </button>
      </div>
    </div>
  )
}

export default Alert