export interface EmployerData {
  company: string,
}

export interface VisualsConfig {
  colourScheme: string,
  font: string | null
}

export interface CVData {
  employer: EmployerData,
  video: string,
  content: ContentCVData,
  professionalTimeline: EmploymentData[],
  education: EducationData[],
  showProfessionalLogo: boolean
  showEducationalLogo: boolean
  visualsConfig: VisualsConfig,
  published?: boolean,
  id?: string,
  removed?: boolean,
  completed?: boolean
}

export interface EmploymentData {
  jobTitle: string,
  employer: string,
  startDate: string,
  endDate: string,
  present?: boolean,
  city: string,
  shortDescription: string,
  website: string
}

export interface EducationData {
  degreeTitle: string,
  institution: string,
  startDate: string,
  endDate: string,
  present?: boolean,
  city: string,
  shortDescription: string,
  website: string
}

export interface ContentCVData {
  title: string,
  titleCustom?: string,
  shortSummary: string,
  shortSummaryCustom?: string,
  name: string,
  nameCustom?: string,
  email: string,
  phone: string,
  linkedIn: string
}

export const initialEmployerValue: EmployerData = {
  company: '',
}

export const initialCVContentValue: ContentCVData = {
  title: '',
  shortSummary: '',
  name: '',
  email: '',
  phone: '',
  linkedIn: ''
}

export const initialCVDataValue: CVData = {
  employer: initialEmployerValue,
  video: '',
  content: initialCVContentValue,
  professionalTimeline: [],
  education: [],
  showProfessionalLogo: true,
  showEducationalLogo: true,
  visualsConfig: {
    colourScheme: 'colorful',
    font: null
  }
}

export interface AlertMessage {
  heading: string,
  text: string
}

export interface cvEditorStorage {
  CVs: CVData[]
  cvContent: ContentCVData
  professionalTimeline: EmploymentData[]
  education: EducationData[]
  visualsConfig: {
    colourScheme: string,
    font: string | null
  }
  stepError?: string
  alertMessage: AlertMessage | null,
  firstTimeUser: boolean
}

export const initialCreatorDataValue: cvEditorStorage = {
  CVs: [initialCVDataValue],
  cvContent: initialCVContentValue,
  professionalTimeline: [],
  education: [],
  visualsConfig: {
    colourScheme: 'colorful',
    font: null
  },
  stepError: '',
  alertMessage: null,
  firstTimeUser: true
}

export const isEmployersInformationValid = (store: { getState: () => { cvEditor: { CVs: CVData[] } } }) => {
  const employers: EmployerData[] = []
  store.getState().cvEditor.CVs.forEach((cv: CVData) => {!cv.removed && employers.push(cv.employer)})
  if (employers.length <= 0) return {
    valid: false,
    errors: {global: `Add at least one employer`}
  }
  let isValid = true
  let errors: {[inputName: string]: string} = {}
  employers.forEach((employer, index) => {
    if (!employer.company || employer.company.length <= 0) {
      errors = {...errors, [index]: `Field can't be empty!`}
      isValid = false
    }
  })
  if (!isValid) errors = {...errors, global: 'Something is wrong!'}
  return {
    valid: isValid,
    errors: errors
  }
}

export const areAllVideosRecorded = (store: { getState: () => { cvEditor: { CVs: CVData[] } } }) => {
  let areAllVideosRecorded = true
  store.getState().cvEditor.CVs
    .filter((cv: CVData) => !cv.removed)
    .forEach((cv: CVData) => {if (!cv.video) areAllVideosRecorded = false})
  return areAllVideosRecorded
}
