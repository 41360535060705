import React, {useEffect, useState} from "react"
import {useAuthState} from "react-firebase-hooks/auth"
import {useNavigate} from "react-router-dom"
import {Link} from "react-router-dom"
import {auth, sendPasswordReset} from "../../utils/firebase"
import {login, register, resetPassword} from "../../consts/links"
import SecondaryButton from "../global/SecondaryButton/SecondaryButton"

function Reset() {
  const [email, setEmail] = useState("")
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) return
    if (user) navigate(resetPassword)
  }, [user, loading, navigate])

  return (
    <div
      className="flex items-center justify-center md:min-h-screen antialiased">
      <div className="flex w-full flex-col max-w-md mt-12 md:mt-0 px-4 md:px-0">
        <div className="flex justify-center mb-8">
          <Link to={register}>
              <img width="213" height="41" src="/images/logofullNew.svg" alt="logo" />
          </Link>
        </div>
        <div className="flex-shrink-0 w-full shadow-login bg-primary rounded-2xl">
          <div className="pt-7 px-5 md:px-10 pb-11">
            <div className="mb-10">
              <h1
                className="text-2xl leading-8 font-bold text-center text-white">Reset
                Password</h1>
            </div>
            <div className="flex flex-col mb-5">
              <input
                type="text"
                className="bg-white border-2 border-white text-sm rounded-xl block w-full py-2.5 px-4 focus:outline-none font-bold text-neutral placeholder:text-base-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"/>
            </div>
            <div className="flex flex-col mb-10">
                <SecondaryButton
                  onClick={() => sendPasswordReset(email).catch((err) => alert(err))}
                >Send password reset email</SecondaryButton>
            </div>
            <div className="flex items-center mb-1 text-sm justify-center text-base-200">
              <Link className="text-white font-semibold px-1" to={login}>
                Back to <span className="underline font-semibold">login</span>
              </Link>
            </div>
            <div className="flex items-center text-sm justify-center font-semibold text-white">
              You don’t have an account?
              <Link className="underline ml-1" to={register}>Register here</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reset