import React, { useContext, useState } from "react"
import { DataContext } from "../VideoCvPageEditor"
import SecondaryButton from "../../global/SecondaryButton/SecondaryButton"
import {useDispatch, useSelector} from "react-redux"
import {EducationData, EmploymentData} from "../consts"
import {
  addEducation,
  addEmployment,
  editEducation,
  editEmployer,
  removeEducation,
  removeEmployment,
  updateVisualsConfig,
  setIsEducationalLogoVisible,
  setIsProfessionalLogoVisible,
} from "../../../store/CvEditor/cvEditor.actions"
import TimelineInput from "./TimelineInput"
import PreviewGallery from "../PreviewGallery/PreviewGallery"
import Input from "./Input"
import StepError from "../StepError/StepError"
// import LinkedInImporter from "../CVStep/LinkedInImporter/LinkedInImporter";

const CVStep = () => {
  const [cvOnFocusIndex, setCvOnFocusIndex] = useState<number>()
  const { goToNextStep } = useContext(DataContext)
  const dispatch = useDispatch()

  const fonts: string[] = ['Opensans', 'Roboto', 'Nunito']

  const ColorSelector = () => {
    const colourScheme: string = useSelector((state: Storage) => state.cvEditor?.visualsConfig?.colourScheme)
    const options: { name: string, label: string, colors: { primary: string, border?: string }[] }[] = [
      {
        name: 'colorful',
        label: 'Colorful',
        colors: [{ primary: '#FF3F3F' }, { primary: '#1F86FF' }, { primary: '#50E3C2' }]
      },
      {
        name: 'softColor',
        label: 'Soft Color',
        colors: [{ primary: '#76b4fd' }, { primary: '#f8e4eb' }, { primary: '#e2fafd' }]
      },
      {
        name: 'monoChrome',
        label: 'Monochrome',
        colors: [{ primary: '#fff', border: '#1f86ff' }, { primary: '#393f4d' }, { primary: '#fff', border: '#888f9e' }]
      }
    ]
    return (
      <div className="w-full border border-yellow-400 bg-white rounded-lg p-4">
        <h3 className="text-base mb-4 font-bold">Colour scheme</h3>
        <div className="flex flex-col gap-y-4">
          {options.map((option, index) => (
            <div className="flex items-center" key={index}>
              <input
                id={`color-checkbox-${option.name}`}
                type="checkbox"
                checked={colourScheme === option.name}
                onChange={() => dispatch(updateVisualsConfig({ fieldName: 'colourScheme', data: option.name }))}
                className="w-4 h-4 accent-gray-800 cursor-pointer"
              />
              <label htmlFor={`color-checkbox-${option.name}`} className="ml-2 text-sm leading-7 font-bold flex cursor-pointer flex-1">
                {option.label}
                <div className="flex ml-auto gap-x-2.5">
                  {option.colors.map((color, index) => (
                    <div key={index} className="w-7 h-7 rounded-full " style={{
                      background: color.primary,
                      border: `2px solid ${color.border || color.primary}`
                    }} />
                  ))}
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const FontSelector = () => {
    const value: string | null = useSelector((state: Storage) => state.cvEditor?.visualsConfig?.font)
    return (
      <div className="w-full border border-yellow-400 bg-white rounded-lg p-4">
        <h3 className="text-base mb-4 font-bold">Font</h3>
        <select
          id="font"
          className="bg-white border border-gray-800 sm:text-sm rounded-lg block w-full py-2.5 px-4 font-bold appearance-none focus:outline-none bg-select bg-no-repeat bg-[left_90%_center]"
          value={value || 'not-selected'}
          onChange={(event) => {
            dispatch(updateVisualsConfig({ fieldName: 'font', data: event.target.value }))
          }}
        >
          <option value='not-selected'>Choose font</option>
          {fonts.map(font => (
            <option
              value={font}
              key={font}
            >
              {font}
            </option>
          ))}
        </select>
      </div>
    )
  }

  return (
    <div className="grid xl:grid-cols-12 gap-x-0 xl:gap-x-10">
      <div className="py-8 xl:py-12 xl:col-span-5">
        <h3 className="font-bold text-xl md:text-2xl mb-1">Add details to your CV pages 💪</h3>
        <p className="font-normal text-sm text-gray-500 mb-5">You can customize the title and a short summary</p>
        {/* <LinkedInImporter /> */}
        <div className="flex flex-col w-full bg-pale-100  pt-5 pb-7 px-5 rounded-xl mb-7">
          <h2 className="w-full text-center block font-bold mb-6">Page settings</h2>
          <div className="flex gap-x-6">
            <ColorSelector />
            <FontSelector />
          </div>
        </div>
        <div className="flex flex-col w-full bg-pale-100  pt-5 pb-7 px-5 rounded-xl mb-7">
          <Input
            label="Title"
            name="title"
            textarea
            charactersLimit={100}
            customizeByEmployer
            cvOnFocusIndex={cvOnFocusIndex}
            onEmployerSelect={(index) => setCvOnFocusIndex(index)}
            placeholder="Example: My application for Employer X"
          />
        </div>
        <div className="flex flex-col w-full bg-pale-100  pt-5 pb-7 px-5 rounded-xl mb-7">
          <Input
            label="Your name"
            name="name"
            charactersLimit={100}
            textarea
            customizeByEmployer
            cvOnFocusIndex={cvOnFocusIndex}
            onEmployerSelect={(index) => setCvOnFocusIndex(index)}
            placeholder="Example: My name is John Doe"
          />
        </div>
        <div className="flex flex-col w-full bg-pale-100  pt-5 pb-7 px-5 rounded-xl mb-7">
          <Input
            label="Short summary"
            name="shortSummary"
            charactersLimit={300}
            textarea
            customizeByEmployer
            cvOnFocusIndex={cvOnFocusIndex}
            onEmployerSelect={(index) => setCvOnFocusIndex(index)}
            placeholder="Example: A strong candidate with great expertise"
          />
        </div>

        <TimelineInput
          editorField='professionalTimeline'
          sectionLabel='Professional timeline'
          submitButtonLabel='Add Employer'
          inputLabels={['Job title', 'Employer']}
          fields={['jobTitle', 'employer']}
          initialValue={{
            jobTitle: '', employer: '', startDate: '', endDate: '', city: '', shortDescription: '', website: ''
          }}
          remove={(index: number) => dispatch(removeEmployment(index))}
          edit={({index, data}: { index: number, data: EmploymentData }) => dispatch(editEmployer({index, data}))}
          submit={(data: any) => dispatch(addEmployment(data))}
          toggleVisibility={(isVisible: boolean, index?: number) => {
            dispatch(setIsProfessionalLogoVisible({isVisible: isVisible, index: index}))
          }}
        />
        <TimelineInput
          editorField='education'
          sectionLabel='Educational timeline'
          submitButtonLabel='Add Education'
          inputLabels={['Degree title', 'Institution']}
          fields={['degreeTitle', 'institution']}
          initialValue={{
            degreeTitle: '', institution: '', startDate: '', endDate: '', city: '', shortDescription: '', website: ''
          }}
          remove={(index: number) => dispatch(removeEducation(index))}
          edit={({index, data}: { index: number, data: EducationData }) => dispatch(editEducation({index, data}))}
          submit={(data: any) => dispatch(addEducation(data))}
          toggleVisibility={(isVisible: boolean, index?: number) => {
            dispatch(setIsEducationalLogoVisible({isVisible: isVisible, index: index}))
          }}
        />
        <div className="flex flex-col w-full bg-pale-100  pt-5 pb-7 px-5 rounded-xl mb-7">
          <h2 className="w-full text-center block font-bold mb-6">Contact details</h2>
          <Input label="Email" name="email" charactersLimit={100} placeholder="Enter here" />
          <Input label="Phone" name="phone" charactersLimit={100} placeholder="Enter here" />
          <Input label="LinkedIn" name="linkedIn" charactersLimit={100} placeholder="Add Linkedin profile link" />
        </div>
        <div className="mt-8">
          <SecondaryButton onClick={() => goToNextStep()}>Next</SecondaryButton>
        </div>
        <StepError />
      </div>
      <PreviewGallery
        currentSlide={(cvOnFocusIndex || cvOnFocusIndex === 0) ? cvOnFocusIndex : undefined}
        setCurrentSlide={setCvOnFocusIndex}
      />
    </div>
  )
}

export default CVStep
