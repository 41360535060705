import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from "../VideoCvPageEditor"
import EmployerForm from "./EmployerForm/EmployerForm"
import { CVData, isEmployersInformationValid } from "../consts"
import store from "../../../store/createStore"
import Loading from "../../../layouts/Loading/Loading"
import { useDispatch } from "react-redux"
import { addEmployer, removeEmployer, setStepError } from "../../../store/CvEditor/cvEditor.actions"
import SecondaryButton from "../../global/SecondaryButton/SecondaryButton"
import TertiaryButton from '../../global/TertiaryButton/TertiaryButton'
import PreviewGallery from "../PreviewGallery/PreviewGallery"
import StepError from "../StepError/StepError"
import Alert from "../../Alert/Alert"
import PrimaryButton from "../../global/PrimaryButton/PrimaryButton"
import {instance} from "../../../utils/axios"

const EmployerStep = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [CVs, setCVs] = useState<CVData[]>([])
  const [errors, setErrors] = useState<{ [index: number]: string }>({})
  const [deleting, setDeleting] = useState<boolean>(false)
  const [deletingCvIndex, setDeletingCvIndex] = useState<number | null>(null)
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] = useState<boolean>(false)
  const [cvOnFocusIndex, setCvOnFocusIndex] = useState<number>(
    store.getState().cvEditor.CVs.length && store.getState().cvEditor.CVs.length - 1
  )
  const dispatch = useDispatch()
  const refreshEmployers = () => setCVs(store.getState().cvEditor.CVs)
  useEffect(() => {
    refreshEmployers()
    setLoading(false)
  }, [])
  const { goToNextStep, checkIfSessionExpired } = useContext(DataContext)

  return (
    loading ?
      <Loading /> :
      <div className='grid xl:grid-cols-12 gap-x-0 xl:gap-x-10 flex-1'>
        {deleteConfirmationOpened && (
          <Alert onDismiss={(event) => {
            event.stopPropagation()
            setDeleteConfirmationOpened(false)
          }}>
            <p className="text-center font-bold text-base text-neutral mb-5">
              Are you sure you want to delete this employer? <br/>This will delete the corresponding page.
            </p>
            <div className="flex gap-6 md:gap-0 justify-center items-center w-full">
              <PrimaryButton disabled={deleting} loading={deleting} onClick={(event) => {
                event.stopPropagation()
                if (deletingCvIndex === null) return
                const cvId = CVs[deletingCvIndex].id
                const onDelete = () => {
                  dispatch(removeEmployer({id: cvId, index: deletingCvIndex}))
                  const CVsLength = store.getState().cvEditor.CVs.length
                  cvOnFocusIndex && cvOnFocusIndex > CVsLength - 1 && setCvOnFocusIndex(CVsLength - 1)
                  setErrors({...errors, [deletingCvIndex]: ''})
                  refreshEmployers()
                  setDeleting(false)
                  setDeletingCvIndex(null)
                  setDeleteConfirmationOpened(false)
                }
                setDeleting(true)
                const deleteCv = async (id: string) => {
                  try {
                    const idToken = store.getState().user?.idToken
                    const accessToken = store.getState().user?.accessToken
                    const response = await instance.delete(
                      "cv",
                      {
                        params: { id: id },
                        headers: { "Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}` }
                      }
                    )
                    if (response.status === 204) onDelete()
                    setDeleting(false)
                  } catch (err: any) {
                    checkIfSessionExpired(err)
                    setDeleting(false)
                  }
                }
                cvId ? deleteCv(cvId) : onDelete()
              }}>
                <span className='md:w-36'>Yes</span>
              </PrimaryButton>
              <TertiaryButton onClick={(event) => {
                event.stopPropagation()
                setDeleteConfirmationOpened(false)
              }}>
                <span className='md:w-36'>No</span>
              </TertiaryButton>
            </div>
          </Alert>
        )}
        <div className='py-8 xl:py-12 xl:col-span-5'>
          <h3 className='font-bold text-xl md:text-2xl mb-1'>Add your prospective employers ✨</h3>
          <p className='font-normal text-sm text-gray-500 mb-5'>For each employer, a unique page will be created</p>
          {CVs.map((cv, index) => !cv.removed && (
            <EmployerForm
              key={index}
              index={index}
              error={errors[index]}
              deleteEmployer={() => {
                setDeletingCvIndex(index)
                setDeleteConfirmationOpened(true)
              }}
            />
          ))}
          {CVs.filter(cv => !cv.removed).length < 20 && (
            <div className='mt-6'>
              <TertiaryButton
                onClick={() => {
                  setErrors(isEmployersInformationValid(store).errors)
                  dispatch(addEmployer())
                  refreshEmployers()
                  setCvOnFocusIndex(store.getState().cvEditor.CVs.length - 1)
                }}
              > <img src="/images/add.svg" className='rotate-45' alt="add" />
                {CVs.filter(cv => !cv.removed).length ? 'Add another employer' : 'Click to add first employer'}
              </TertiaryButton>
            </div>
          )}
          <div className='mt-10 lg:mt-20 mb-10'>
            <SecondaryButton onClick={() => {
              if (isEmployersInformationValid(store).valid) {
                goToNextStep()
              } else {
                isEmployersInformationValid(store).errors.global && dispatch(
                  setStepError(isEmployersInformationValid(store).errors.global)
                )
                setErrors(isEmployersInformationValid(store).errors)
              }
            }}>Next</SecondaryButton>
            <StepError />
          </div>
        </div>
        <PreviewGallery
          currentSlide={(cvOnFocusIndex || cvOnFocusIndex === 0) ? cvOnFocusIndex : undefined}
          setCurrentSlide={setCvOnFocusIndex}
        />
      </div>
  )
}

export default EmployerStep