import cvEditorTypes from './cvEditor.types'
import {
  ContentCVData,
  initialCreatorDataValue,
  initialCVDataValue
} from "../../components/VideoCvPageEditor/consts"

const INITIAL_STATE = initialCreatorDataValue

const websiteReducer = (state= INITIAL_STATE, action: any) => {
   switch (action.type) {
     case cvEditorTypes.SET_CVS: {
       return { ...state, CVs: action.value }
     }
     case cvEditorTypes.SET_IS_FIRST_TIME_USER: {
       return { ...state, firstTimeUser: action.value }
     }
     case cvEditorTypes.SET_CV_CONTENT: {
       const CVs = [...state.CVs]
       return { ...state, cvContent: action.value, CVs: CVs }
     }
     case cvEditorTypes.UPDATE_CV_CONTENT: {
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.content[action.value.fieldName as keyof ContentCVData] = action.value.data)
       return { ...state, cvContent: {...state.cvContent, [action.value.fieldName]: action.value.data}, CVs: CVs }
     }
     case cvEditorTypes.REMOVE_EMPLOYER_ADAPTATION: {
       const CVs = [...state.CVs]
       CVs[action.value.index].content[action.value.fieldName as keyof ContentCVData] = ''
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.SET_EMPLOYER: {
       const CVs = [...state.CVs]
       CVs[action.value.index] = {...CVs[action.value.index], employer: action.value.data}
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.ADD_EMPLOYER: {
       const CVs = [...state.CVs]
       CVs.push({
         ...initialCVDataValue,
         content: state.cvContent || initialCVDataValue.content,
         visualsConfig: state.visualsConfig || initialCreatorDataValue.visualsConfig,
         education: state.education || initialCVDataValue.education,
         professionalTimeline: state.professionalTimeline || initialCVDataValue.professionalTimeline,
       })
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.REMOVE_EMPLOYER: {
       const CVs = [...state.CVs]
       action.value.id ?
         CVs[CVs.findIndex(cv => cv.id === action.value?.id)].removed = true :
         CVs.splice(action.value.index, 1)
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.ADD_EMPLOYMENT: {
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.professionalTimeline = [ ...state.professionalTimeline, action.value ])
       return { ...state, professionalTimeline: [ ...state.professionalTimeline, action.value ]}
     }
     case cvEditorTypes.EDIT_EMPLOYER: {
       const professionalTimeline = [...state.professionalTimeline]
       professionalTimeline[action.value.index] = action.value.data
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.professionalTimeline = professionalTimeline)
       return { ...state, professionalTimeline: professionalTimeline }
     }
     case cvEditorTypes.REMOVE_EMPLOYMENT: {
       const professionalTimeline = [...state.professionalTimeline]
       professionalTimeline.splice(action.index, 1)
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.professionalTimeline = professionalTimeline)
       return { ...state, professionalTimeline: professionalTimeline }
     }
     case cvEditorTypes.SET_EMPLOYMENT: {
       return { ...state, professionalTimeline: action.value }
     }
     case cvEditorTypes.ADD_EDUCATION: {
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.education = [ ...state.education, action.value ])
       return { ...state, education: [ ...state.education, action.value ]}
     }
     case cvEditorTypes.EDIT_EDUCATION: {
       const education = [...state.education]
       education[action.value.index] = action.value.data
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.education = education)
       return { ...state, education: education }
     }
     case cvEditorTypes.REMOVE_EDUCATION: {
       const education = [...state.education]
       education.splice(action.index, 1)
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.education = education)
       return { ...state, education: education }
     }
     case cvEditorTypes.SET_EDUCATION: {
       return { ...state, education: action.value }
     }
     case cvEditorTypes.SET_VISUALS_CONFIG: {
       return {...state, visualsConfig: action.value}
     }
     case cvEditorTypes.UPDATE_VISUALS_CONFIG: {
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.visualsConfig = {...state.visualsConfig, [action.value.fieldName]: action.value.data})
       return {...state, visualsConfig: {...state.visualsConfig, [action.value.fieldName]: action.value.data}, CVs: CVs}
     }
     case cvEditorTypes.SET_VIDEO: {
       const CVs = [...state.CVs]
       CVs[action.value.index].video = action.value.url
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.SET_CONTENT: {
       const CVs = [...state.CVs]
       for (let i = 0; i < CVs.length; i++) { CVs[i].content = action.value.content }
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.SET_EMPLOYER_CONTENT: {
       const CVs = [...state.CVs]
       CVs[action.value.index].content = action.value.data
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.SET_PUBLISHED: {
       const CVs = [...state.CVs]
       CVs[action.value.index].published = action.value.data
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.SET_ALL_CVS_PUBLISHED: {
       const CVs = [...state.CVs]
       CVs.forEach(cv => cv.published = true)
       return { ...state, CVs: CVs }
     }
     case cvEditorTypes.SET_STEP_ERROR: {
       return { ...state, stepError: action.value, alertMessage: {heading: action.value, text: 'Hello'}}
     }
     case cvEditorTypes.CLEAR_STEP_ERROR: {
       return { ...state, stepError: '', alertMessage: null }
     }
     case cvEditorTypes.SET_ALERT_MESSAGE: {
       return { ...state, alertMessage: {heading: action.value, text: 'Hello'} }
     }
     case cvEditorTypes.CLEAR_ALERT_MESSAGE: {
       return { ...state, alertMessage: null }
     }
     case cvEditorTypes.SET_IS_PROFESSIONAL_LOGO_VISIBLE: {
      const CVs = [...state.CVs]
      CVs.forEach(cv => cv.showProfessionalLogo = action.value.isVisible)
      return { ...state, CVs: CVs }
    }
    case cvEditorTypes.SET_IS_EDUCATIONAL_LOGO_VISIBLE: {
      const CVs = [...state.CVs]
      CVs.forEach(cv => cv.showEducationalLogo = action.value.isVisible)
      return { ...state, CVs: CVs }
    }
     default: return state
   }
}

export default websiteReducer
