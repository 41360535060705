import React from 'react'
import {useNavigate} from "react-router-dom"
import {createCV} from "../../consts/links"
import {fireAnalyticsEvent} from '../../utils/analytics'
import {analyticsEvents} from "../../consts/analyticsEvents"

const Onboarding = () => {
  const navigate = useNavigate()
  return (
    <div className='grid lg:grid-cols-[4fr_6fr] gap-x-16 py-8 lg:py-12 w-full'>
      <div className='lg:pt-10'>
        <h1 className='text-3xl lg:text-5xl font-extrabold mb-4 lg:mb-5 text-center lg:text-left'>Stand out, get the interview 🤝 </h1>
        <h4 className='text-base lg:text-x font-semibold mb-4 lg:mb-10 lg:max-w-xs text-center lg:text-left'>Impress your hiring manager for real 🤩 </h4>
        <div className='flex flex-col items-center lg:items-end mb-6 lg:mb-8 text-center lg:text-left'>
          <p className='font-writing text-2xl lg:text-3xl text-primary max-w-xs lg:-rotate-6'>Create video CV pages for each application in minutes</p>
          <img className='-mt-2 hidden lg:inline-block' src="/images/redarrow.svg" alt="" />
        </div>
        <button
          onClick={() => {
            navigate(createCV)
            fireAnalyticsEvent(analyticsEvents.CREATE_CV)
          }}
          className='bg-secondary w-full lg:w-auto border border-secondary text-sm lg:text-base rounded-xl px-3 sm:px-8 h-12 lg:h-14 text-white font-extrabold duration-300 transition-colors hover:bg-secondary/80 hover:border-secondary/80 active:scale-95'
        >
          Start creating now
        </button>
        <div className='flex mt-4 mb-8 justify-center lg:justify-start'>
          <p className='font-writing text-xl lg:text-2xl text-primary lg:ml-32'>No worries, publishing is FREE!!</p>
        </div>
      </div>
      <div className="w-full shadow-login">
        <img src="/images/template.png" alt="template" />
      </div>
    </div>
  )
}

export default Onboarding