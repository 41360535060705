export const quick_help = [
  {
    title: "Email us",
    image_url: "/images/email.svg",
    alt: "email icon",
    linkto: "mailto:contact@videocvpage.com",
    text: "contact@videocvpage.com",
    supportive_text: "",
  },

  {
    title: "Chat with us",
    image_url: "/images/chat.svg",
    alt: "chat icon",
    linkto: "https://videocvpage.com",
    text: "Click here",
    supportive_text: " to go to our website where you can see the chat window",
  },
];
