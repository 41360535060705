import userTypes from "./user.types"

export const setIdToken = (value: string) => ({ type: userTypes.SET_ID_TOKEN, value })
export const destroyIdToken = () => ({ type: userTypes.DESTROY_ID_TOKEN })
export const setAccessToken = (value: string) => ({ type: userTypes.SET_ACCESS_TOKEN, value })
export const destroyAccessToken = () => ({ type: userTypes.DESTROY_ACCESS_TOKEN })
export const addUserData = (userData: {
    name: string;
    email: string;
    isGoogleSignedIn: string | null;
}) => ({
    type: userTypes.ADD_USER_DATA,
    userData,
});

export const addUserDataUpdate = (userData: {
    name: string;
    email: string;
}) => ({
    type: userTypes.ADD_USER_DATA_UPDATE,
    userData,
});

export const updateUserData = (userData: { name: string }) => ({
    type: userTypes.UPDATE_USER_DATA,
    userData,
});

export const removerUserData = () => ({ type: userTypes.REMOVE_USER_DATA });