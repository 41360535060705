import React, {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import EmployerStep from "./EmployerStep/EmployerStep"
import VideoStep from "./VideoStep/VideoStep"
import CVStep from "./CVStep/CVStep"
import PublishStep from "./PublishStep/PublishStep"
import TemplateStep from "./TemplateStep/TemplateStep"
import { instance } from "../../utils/axios"
import { useDispatch, useSelector } from "react-redux"
import {
  clearAlertMessage,
  clearStepError,
  setAlertMessage,
  setAllCVsPublished,
  setCVContent,
  setCVs,
  setEducation,
  setEmployment,
  setIsFirstTimeUser,
  setVisualConfig
} from "../../store/CvEditor/cvEditor.actions"
import {
  AlertMessage,
  ContentCVData,
  CVData,
  cvEditorStorage,
  EducationData,
  EmploymentData,
  VisualsConfig
} from "./consts"
import store from "../../store/createStore"
import PrimaryButton from "../global/PrimaryButton/PrimaryButton"
import PrimaryLayout from "../../layouts/PrimaryLayout/PrimaryLayout"
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt"
import Alert from "../Alert/Alert"
import TertiaryButton from "../global/TertiaryButton/TertiaryButton"
import { areCVsUpdated, handleError } from "../../utils/functions"
import Loading from "../../layouts/Loading/Loading"
import BuilderNavigation from "./BuilderNavigation/BuilderNavigation"

export const DataContext = createContext<{
  goToNextStep: () => void,
  goToPrevStep: () => void,
  goToStep: (index: number) => void,
  publish: (draft?: boolean, finalStep?: boolean, preventStepReload?: boolean) => Promise<any>,
  checkIfSessionExpired: (err: any) => void
}>({
  goToNextStep: () => { },
  goToPrevStep: () => { },
  goToStep: () => { },
  publish: async () => { },
  checkIfSessionExpired: () => { }
})

const VideoCvPageEditor = () => {

  const [currentStep, setCurrentStep] = useState<number>(0)
  const firstTimeUser: boolean = useSelector((state: Storage) => state.cvEditor?.firstTimeUser)
  const dispatch = useDispatch()
  const [initialCVsValue, setInitialCVsValue] = useState<CVData[]>([])
  const [preventQuit, setPreventQuit] = useState<boolean>(true)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(preventQuit)
  const [cvsLoading, setCvsLoading] = useState<boolean>(true)
  const [contentLoading, setContentLoading] = useState<boolean>(true)

  const currentError = useRef()
  const checkIfSessionExpired = (err: any) => {
    if (err?.response?.data?.error === "Session expired") {
      setPreventQuit(false)
      currentError.current = err
    } else {
      handleError(err)
    }
  }
  useEffect(() => {!preventQuit && handleError(currentError?.current)}, [preventQuit])

  const publish = useCallback(async (draft?: boolean, finalStep?: boolean, preventStepReload?: boolean) => {
    const CVEditor: cvEditorStorage = store.getState().cvEditor
    try {
      const idToken = store.getState().user?.idToken
      const accessToken = store.getState().user?.accessToken
      const response = await instance.post(
        'cv/create',
        {
          cvs: CVEditor.CVs.filter(cv => !cv.removed),
          cvContent: CVEditor.cvContent,
          professionalTimeline: CVEditor.professionalTimeline,
          education: CVEditor.education,
          visualsConfig: CVEditor.visualsConfig,
          draft: draft
        },
        { headers: { "Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}` } }
      )
      if (response.status === 201) {
        const cvs = [...response?.data?.cvs]
        cvs && dispatch(setCVs(cvs))
        if (!preventStepReload) setInitialCVsValue(JSON.parse(JSON.stringify(cvs)))
        if (!draft) {
          dispatch(setIsFirstTimeUser(false))
          dispatch(setAllCVsPublished())
          finalStep && dispatch(setAlertMessage('Your pages are published successfully'))
        }
        return
      }
      else return alert('something is wrong')
    } catch (err: any) {
      checkIfSessionExpired(err)
      throw new Error(err)
    }
  }, [dispatch])
  const goToNextStep = useCallback(() => {
    if (currentStep > 0) publish(true)
    dispatch(clearStepError())
    setCurrentStep(currentStep + 1)
  }, [publish, dispatch, currentStep])
  const goToPrevStep = useCallback(() => setCurrentStep(currentStep - 1), [currentStep])
  const goToStep = (index: number) => setCurrentStep(index)

  const value = useMemo(() => ({
    goToNextStep, goToPrevStep, goToStep, publish, checkIfSessionExpired
  }), [goToNextStep, goToPrevStep, publish])

  useEffect(() => {
    const getUserCVs = async () => {
      try {
        const idToken = store.getState().user?.idToken
        const accessToken = store.getState().user?.accessToken
        const response: { data: CVData[] } = await instance.get(
          "cv/load",
          { headers: { "Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}` } }
        )
        const cvs = [...response.data]
        setInitialCVsValue(JSON.parse(JSON.stringify(cvs)))
        dispatch(setIsFirstTimeUser(!(cvs.filter(cv => cv.id && cv.completed).length)))
        dispatch(setCVs(cvs))
      } catch (err: any) { checkIfSessionExpired(err) }
    }
    const getGeneralCVContent = async () => {
      try {
        const idToken = store.getState().user?.idToken
        const accessToken = store.getState().user?.accessToken
        const response: {
          data: {
            cvContent: ContentCVData,
            professionalTimeline: EmploymentData[],
            education: EducationData[],
            visualsConfig: VisualsConfig
          }
        } = await instance.get(
          "user", { headers: { "Authorization": `Bearer idToken:${idToken} accessToken:${accessToken}` } }
        )
        response.data.cvContent && dispatch(setCVContent(response.data.cvContent))
        response.data.professionalTimeline && dispatch(setEmployment(response.data.professionalTimeline))
        response.data.education && dispatch(setEducation(response.data.education))
        response.data.visualsConfig && dispatch(setVisualConfig(response.data.visualsConfig))
      } catch (err: any) { checkIfSessionExpired(err) }
    }
    getUserCVs()
      .then(() => setCvsLoading(false))
      .catch((error) => {
        setCvsLoading(false)
        checkIfSessionExpired(error)
      })
    getGeneralCVContent()
      .then(() => setContentLoading(false))
      .catch((error) => {
        setContentLoading(false)
        checkIfSessionExpired(error)
      })
  }, [dispatch])

  const StepBody = () => {
    switch (currentStep) {
      case 0: return <TemplateStep />
      case 1: return <EmployerStep />
      case 2: return <VideoStep />
      case 3: return <CVStep />
      case 4: return (
        <PublishStep
          initialCVsValue={initialCVsValue || []}
          deleteCV={(id: string) => {
            const CVsValue = [...initialCVsValue]
            CVsValue[CVsValue.findIndex(cv => cv.id === id)].removed = true
            setInitialCVsValue(CVsValue)
          }}
        />
      )
      default: return <EmployerStep />
    }
  }

  const DialogAlert = () => {
    const alertMessage: AlertMessage = useSelector((state: Storage) => state.cvEditor?.alertMessage)
    const [error, setError] = useState<AlertMessage | null>(alertMessage)
    useEffect(() => { alertMessage && setError(alertMessage) }, [alertMessage])
    return error && (
      <Alert
        onDismiss={() => {
          dispatch(clearAlertMessage())
          setError(null)
        }}
      >
        <div className="flex flex-col items-center gap-6 w-full max-w-sm md:px-2">
          <p className="text-center font-bold text-base text-neutral">
            {alertMessage.heading}
          </p>
          <div className="flex gap-6 md:gap-0 justify-center items-center w-full">
            <PrimaryButton onClick={() => {
              dispatch(clearAlertMessage())
              setError(null)
            }}> <span className='md:w-36'>Close</span>
            </PrimaryButton>
          </div>
        </div>
      </Alert>
    )
  }

  return (
    cvsLoading || contentLoading ? <Loading /> :
      <PrimaryLayout editorMode>
        {showPrompt && (
          areCVsUpdated(initialCVsValue, store.getState().cvEditor?.CVs) && !firstTimeUser ?
            <Alert onDismiss={() => typeof cancelNavigation === 'function' && cancelNavigation()}>
              <div className="flex flex-col items-center gap-6 w-full max-w-sm md:px-2">
                <p className="text-center font-bold text-base text-neutral">
                  You have unpublished changes. Are you sure you want to leave? Go to publish page to save & publish changes
                </p>
                <div className="flex gap-6 md:gap-0 justify-center items-center w-full">
                  <PrimaryButton onClick={() => typeof confirmNavigation === 'function' && confirmNavigation()}>
                    <span className='md:w-36'>Yes</span>
                  </PrimaryButton>
                  <TertiaryButton onClick={() => typeof cancelNavigation === 'function' && cancelNavigation()}>
                    <span className='md:w-36'>No</span>
                  </TertiaryButton>
                </div>
              </div>
            </Alert> : typeof confirmNavigation === 'function' && confirmNavigation()
        )}
        <DataContext.Provider value={value}>
          <div className="bg-progress py-4 flex-1 flex flex-col md:pb-0">
            <BuilderNavigation currentStep={currentStep} initialCVsValue={initialCVsValue} />
            <div className='flex-1 flex flex-col'>
              <div className="bg-base-300 flex flex-col flex-1">
                <div className='mx-auto w-full max-w-screen-2xl px-3 md:px-5 flex flex-col flex-1'>
                  <StepBody />
                </div>
              </div>
            </div>
          </div>
        </DataContext.Provider>
        <DialogAlert />
      </PrimaryLayout>
  )
}

export default VideoCvPageEditor