import React, {useContext, useState} from "react"
import {CVData, cvEditorStorage} from "../consts"
import {useDispatch, useSelector} from "react-redux"
import {DataContext} from "../VideoCvPageEditor"
import CVCard from "../CVCard/CVCard"
import SecondaryButton from "../../global/SecondaryButton/SecondaryButton"
import TertiaryButton from "../../global/TertiaryButton/TertiaryButton"
import {removeEmployer} from "../../../store/CvEditor/cvEditor.actions"
import {areCVsUpdated} from "../../../utils/functions"
import {fireAnalyticsEvent} from "../../../utils/analytics"
import {analyticsEvents} from "../../../consts/analyticsEvents"

const PublishStep = ({initialCVsValue, deleteCV}: {initialCVsValue: CVData[], deleteCV: (id: string) => void}) => {

  const [saving, setSaving] = useState<boolean>(false)
  const CVEditor: cvEditorStorage = useSelector((state: Storage) => state.cvEditor)
  const firstTimeUser: boolean = useSelector((state: Storage) => state.cvEditor?.firstTimeUser)
  const { goToStep, publish, checkIfSessionExpired } = useContext(DataContext)
  const dispatch = useDispatch()

  return (
    <div className="py-6">
      {firstTimeUser && <h2 className="text-lg font-bold mb-4">Your pages are ready to be published</h2>}
      {(areCVsUpdated(initialCVsValue, CVEditor.CVs) && !firstTimeUser) && (
        <p className='text-error text-base md:text-lg leading-6 font-bold mb-4'>You have unpublished changes</p>
      )}
      {(areCVsUpdated(initialCVsValue, CVEditor.CVs) || firstTimeUser) ? (
        <SecondaryButton disabled={saving} loading={saving} onClick={() => {
          setSaving(true)
          publish(false, true)
            .then(() => setSaving(false))
            .catch(() => setSaving(false))
          firstTimeUser && fireAnalyticsEvent(analyticsEvents.NEW_PUBLISH_CV)
        }}>
          {firstTimeUser ? 'Publish your pages now' : 'Save and publish changes'}
        </SecondaryButton>
      ) : (
        <div className="flex">
          <div className="flex flex-col">
          <div className="flex items-center gap-3 mb-2">
            <h3 className="text-success text-lg font-bold">Your video CV pages have been published</h3>
            <img src="/images/tick.svg" alt="done"/>
          </div>
              <p className="text-sm">You can now share your video cv pages with your prospective employers ➡️</p>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-11 mt-8">
        {CVEditor.CVs.map((cv, index) => cv.id && cv.completed && !cv.removed && (
          <CVCard
            cv={cv}
            index={index}
            key={cv.id}
            onEdit={() => goToStep(1)}
            onDelete={() => {
              dispatch(removeEmployer({id: cv.id, index: index}))
              cv.id && deleteCV(cv.id)
            }}
            onError={(error: any) => checkIfSessionExpired(error)}
          />
        ))}
        {!firstTimeUser && (
          <div className="flex justify-center items-center pb-8">
            <TertiaryButton onClick={() => goToStep(1)}>
              <img src="/images/add.svg" className='rotate-45' alt="add"/> Add more employers
            </TertiaryButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default PublishStep