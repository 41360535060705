import React, {useEffect, useState} from 'react'
import { setup, isSupported } from "@loomhq/record-sdk"
import uuid from 'react-uuid'
import LoadingSpinner from "../global/LoadingSpinner/LoadingSpinner"

const PUBLIC_APP_ID = process.env.REACT_APP_LOOM_SDK_KEY
const BUTTON_ID = "loom-record-sdk-button" + uuid()

const Loom = ({onVideoCreated, updateMode}: {onVideoCreated: (videoUrl: string) => void, updateMode?: boolean}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>()
  const [videoRecorded, setVideoRecorded] = useState<boolean>(false)

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported()

      if (!supported) {
        error && setError(`Error setting up Loom: ${error}`)
        return console.warn(`Error setting up Loom: ${error}`)
      }

      const button = document.getElementById(BUTTON_ID)

      if (!button) return

      const { configureButton } = await setup({publicAppId: PUBLIC_APP_ID,})

      const sdkButton = configureButton({ element: button })
      setLoading(false)

      sdkButton.on("insert-click", async (video: { sharedUrl: string }) => {
        onVideoCreated(video.sharedUrl)
        setVideoRecorded(true)
      })
    }

    setupLoom()
  }, [onVideoCreated])

  return !videoRecorded ? (
    <div>
      <button
        className='flex items-center justify-center bg-error border border-error text-center text-sm rounded-lg px-3 sm:px-6 h-11 text-white font-extrabold duration-300 transition-colors hover:border-error/80 hover:bg-error/80 active:scale-95'
        id={BUTTON_ID}
      >
        {loading ?
          <><LoadingSpinner small/> Loom plugin is loading</> :
          <>
            <img className='mr-2' src="/images/camera.svg" alt="icon"/> {
              updateMode ? 'Record video again' : 'Start to record video'
            }
          </>
        }
      </button>
      {error && <p>{error}</p>}
      <div className='flex items-center text-sm mt-5'>
        <img className='mr-2.5' width="34" height="34" src="/images/loom.svg" alt="icon"/>
        A popup will show to record a video with Loom.<br /> Don't forget to "Insert recording"! 
      </div>
    </div>
  ) : null
}

export default Loom