import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'
import {CVData} from "../components/VideoCvPageEditor/consts"
import {composeWithDevTools} from "redux-devtools-extension"

export const middlewares  = [thunk]

const composeEnhancer = compose

export interface Storage {
  cvEditor: CVData[],
  user: {
    accessToken: string | null
    idToken: string | null
    name: string | null
    email: string | null
    isGoogleSignedIn: string | null
  }
}

export const store = createStore(rootReducer, composeWithDevTools(composeEnhancer(applyMiddleware(...middlewares))))

export default store
