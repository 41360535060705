import React, {useContext} from 'react'
import {areAllVideosRecorded, CVData, cvEditorStorage, isEmployersInformationValid} from "../consts"
import store from "../../../store/createStore"
import {setStepError} from "../../../store/CvEditor/cvEditor.actions"
import {useDispatch, useSelector} from "react-redux"
import {DataContext} from "../VideoCvPageEditor"
import {areCVsUpdated} from "../../../utils/functions";

const BuilderNavigation = ({currentStep, initialCVsValue} : {currentStep: number, initialCVsValue: CVData[]}) => {

  const CVEditor: cvEditorStorage = useSelector((state: Storage) => state.cvEditor)
  const firstTimeUser: boolean = useSelector((state: Storage) => state.cvEditor?.firstTimeUser)
  const dispatch = useDispatch()
  const menuSteps: string[] = [
    'Choose page template', 'Add employers', 'Add personal videos', 'Add CV details', 'Publish & share'
  ]
  const { goToStep, publish } = useContext(DataContext)

  return (
    <div>
      <div className="mx-auto w-full max-w-screen-2xl px-3 md:px-5">
      <div className="w-full h-1 bg-base-500 rounded-sm overflow-hidden mb-4">
        <div
          className="bg-primary-200 rounded-sm h-1"
          style={{width: `${(currentStep + 1) * 100/menuSteps.length}%`}}
        />
      </div>
        <div className="grid grid-col-1 md:grid-cols-5 items-start gap-y-2">
        {menuSteps.map((step, index) => {
          const isLastStep = index + 1 === menuSteps.length
          return (
            <div className='w-full' key={index}>
              <button
                className={`
                  ${index === currentStep ?
                  isLastStep && areCVsUpdated(initialCVsValue, CVEditor.CVs) ? 
                    'bg-white text-red-500 hover:text-red-500/80' : 
                    'bg-primary text-white hover:text-white/80' :
                  `bg-white ${firstTimeUser && index > currentStep ?
                    'text-gray-400 hover:text-gray-400/80' :
                    isLastStep && areCVsUpdated(initialCVsValue, CVEditor.CVs) ?
                      'text-red-500 hover:text-red-500/80' :
                      'text-primary hover:text-primary/80'}`
                }
                    ${index <= currentStep ? 'border-primary' : 'border-gray'}
                    w-full md:rounded-b-none border text-center text-sm rounded-lg px-3 sm:px-6 h-11 font-extrabold
                    duration-300 transition-colors hover:border-primary/80 active:scale-95`
                }
                onClick={() => {
                  if (currentStep > 0 && index > currentStep) firstTimeUser && publish(true)
                  let valid = true
                  let error = ''
                  const setIsValid = (isValid: boolean, errorMessage: string) => {
                    valid = isValid
                    !valid && (error = errorMessage)
                  }
                  if (valid && index > 2) setIsValid(
                    areAllVideosRecorded(store), 'Please record videos for all of your employers')
                  if (valid && index > 1) setIsValid(
                    isEmployersInformationValid(store).valid, isEmployersInformationValid(store).errors?.global)
                  if (index <= currentStep) valid = true
                  if (valid) goToStep(index)
                  else dispatch(setStepError(error))
                }}
              >
                {
                  !firstTimeUser &&
                  isLastStep &&
                  areCVsUpdated(initialCVsValue, CVEditor.CVs) &&
                  index !== currentStep && <span style={{fontSize: 10}}>Unpublished changes, click here to<br/></span>
                }
                {step}
              </button>
            </div>
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default BuilderNavigation