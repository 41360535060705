import  Helmet  from "react-helmet";

interface metaDataProps {
  title: string;
  path: string;
}
const MetaData = ({ title, path }: metaDataProps) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={`${path}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          href={`${path}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${path}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${path}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`${path}/site.webmanifest`} />
        <meta name="msapplication-config" content={`${path}/browserconfig.xml`} />
      </Helmet>
    </div>
  );
};

export default MetaData;
