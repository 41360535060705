import { analyticsEvents } from "../consts/analyticsEvents"
import { analytics } from "./firebase"
import { logEvent } from "firebase/analytics"

export const fireAnalyticsEvent = (action: analyticsEvents) => {
  switch (action) {
    case analyticsEvents.REGISTER_USER: {return logEvent(analytics, "user_signed_up")}
    case analyticsEvents.LOGIN_USER: {return logEvent(analytics, "user_logged_in")}
    case analyticsEvents.CREATE_CV: {return logEvent(analytics, "builder_opened")}
    case analyticsEvents.NEW_PUBLISH_CV: {return logEvent(analytics, "cvs_published_first_time")}
    default: {break}
  }
}
