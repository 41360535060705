import React from "react";

interface AccountSettingsInputProps {
  idValue: string;
  inputValue: string;
  required: boolean;
  disabled: boolean;
  changeInputValue?: React.Dispatch<React.SetStateAction<string>>;
  updatedField?: boolean;
  updatedFieldText?: string;
}

const AccountSettingsInput: React.FC<AccountSettingsInputProps> = (
  {
    idValue,
    inputValue,
    required,
    disabled,
    changeInputValue,
    updatedField,
    updatedFieldText
  }) => {
  return (
    <div className="w-full max-w-xs">
      <input
        type="text"
        id={idValue}
        className="bg-white disabled:text-base-200 text-neutral border border-pale-200 w-full py-3 px-5 rounded-xl text-sm font-semibold focus-visible:outline-pale-200"
        placeholder="Name"
        value={inputValue}
        onChange={(event) => {
          if (changeInputValue) {
            changeInputValue(event.target.value);
          }
        }}
        required={required}
        disabled={disabled}
      />
      {updatedFieldText && updatedField && <p className="text-sm text-primary">{updatedFieldText}</p>}
    </div>
  );
};
export default AccountSettingsInput;
