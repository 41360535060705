import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom"
import {CVData, initialCVDataValue} from "../VideoCvPageEditor/consts"
import {instance} from "../../utils/axios"
import Loading from "../../layouts/Loading/Loading"
import {handleError, loadVariables} from "../../utils/functions"
import Timeline from './Timeline'
import SupportCommunity from '../global/InfoBar/SupportCommunity'
import MetaData from '../../layouts/MetaData/MetaData'

const Cv = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [cv, setCvData] = useState<CVData>(initialCVDataValue)
  const [userName, setuserName] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)
  const {id} = useParams()
  const navigate = useNavigate()

  const handleScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    const getCvData = async () => {
      try {
        const response: { data: { cv: CVData, userName: string } } = await instance.get("cv", {params: {id: id}})
        setCvData(response.data.cv)
        setuserName(response.data.userName)
        window.location.host.split('.')[0] === 'app' &&
        window.location.replace(window.location.href.replace('app.', 'my.'))
        setLoading(false)
      } catch (error) {
        window.location.host.split('.')[0] === 'app' && navigate('/')
        handleError(error)
        setLoading(false)
      }
    }
    getCvData()
  }, [id, navigate])


  if (loading) return <><MetaData title={`Loading`} path="/images/cvFavicon"/><Loading/></>
  if (!cv.id) return <> <MetaData title={`CV Deleted`} path="/images/cvFavicon"/><SupportCommunity/></>
  if (!cv.published) return <div><MetaData title={`Unpublished CV`} path="/images/cvFavicon"/> Unpublished</div>
  return (
    <>
      <MetaData title={`For ${cv.employer.company}, by ${userName}`} path="/images/cvFavicon"/>
      <div className="bg-gray-200 2xl:py-20">
        <div className={`font-${cv.visualsConfig.font} theme-${cv.visualsConfig.colourScheme} cv-template`}>
          <div className="cv-header">
            <div className="cv-header-text">
              <h2>
                {cv.content.titleCustom || loadVariables(cv.content.title, cv) || 'Company Name'}
              </h2>
              <h4>{loadVariables(cv.content.nameCustom || cv.content.name || '', cv)}</h4>
              <p>{loadVariables(cv.content.shortSummaryCustom || cv.content.shortSummary || '', cv)}</p>
              <button onClick={() => cv.id && window.open(cv.video, '_blank')}>Watch Video</button>
            </div>
            <div className='cv-header-video'>
              {cv.video ? (
                <iframe
                  src={
                    `https://www.loom.com/embed/${cv.video?.replace('https://www.loom.com/share/', '')}`
                  }
                  title={cv.video}
                  frameBorder="0"
                  width="400"
                  height="300"
                />
              ) : (
                <div>Video</div>
              )}
            </div>
            <button onClick={handleScroll} type='button' className='cv-scroll-btn'>
              <img src="/images/downarrow.svg" alt="arrow"/>
              Scroll for CV details
            </button>
            <button className='video-btn-xs' onClick={() => cv.id && window.open(cv.video, '_blank')}>Watch Video
            </button>
          </div>
          <Timeline
            ref={scrollRef}
            sectionLabel='Professional timeline'
            timelineItems={cv?.professionalTimeline}
            isLogoVisible={cv.showProfessionalLogo}
          />
          <Timeline
            sectionLabel='Educational timeline'
            timelineItems={cv?.education}
            isLogoVisible={cv.showEducationalLogo}
            listWrapper
          />
          <div className="cv-footer">
            <div className="cv-footer-content">
              {cv.content.email && (
                <div className='cv-footer-unit'>
                  <h5>Email</h5>
                  <a href={`mailto:${cv.content.email}`}>
                    <span>{cv.content.email}</span><img src="/images/linkicon.svg" alt="icon"/>
                  </a>
                </div>
              )}
              {cv.content.phone && (
                <div className='cv-footer-unit'>
                  <h5>Phone Number</h5>
                  <a href={`tel:${cv.content.phone}`}>
                    <span>{cv.content.phone}</span> <img src="/images/linkicon.svg" alt="icon"/>
                  </a>
                </div>
              )}
              {cv.content.linkedIn && (
                <div className='cv-footer-unit'>
                  <h5>Linkedin</h5>
                  <a
                    href={
                      cv.content.linkedIn.substring(0, 8) === "https://" ?
                        cv.content.linkedIn :
                        "https://" + cv.content.linkedIn
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{cv.content.linkedIn}</span> <img src="/images/linkicon.svg" alt="icon"/>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cv
