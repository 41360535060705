import React, { useState } from "react"
import { logInWithEmailAndPassword, signInWithGoogle } from "../../utils/firebase"
import { login, register, resetPassword } from "../../consts/links"

import {Link, useSearchParams} from "react-router-dom"
import GoogleButton from "../global/GoogleButton/GoogleButton"
import SecondaryButton from "../global/SecondaryButton/SecondaryButton"

function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState<{provider: string} | null>(null)
  const [error, setError] = useState<string | null>(null)

  const [params] = useSearchParams()

  return (
    <>
      {params.get("session-expired") && (
        <div className="w-full relative md:absolute inset-x-0 gap-4 p-4 bg-dark-400 text-start md:text-center text-sm font-bold justify-start md:justify-center align-center text-neutral flex">
          <div className="flex flex-col gap-4">Session expired, please login again</div>
        </div>
      )}
      <div className="flex items-center justify-center md:min-h-screen antialiased">
        <div className="flex w-full flex-col max-w-md mt-12 md:mt-0 px-4 md:px-0">
          <div className="flex justify-center mb-8">
            <Link to={login}>
                <img width="213" height="41" src="/images/logofullNew.svg" alt="logo" />
            </Link>
          </div>
          <div className="flex-shrink-0 w-full shadow-login bg-primary rounded-2xl">
            <div className="pt-7 px-5 md:px-10 pb-11">
              <div className="mb-10">
                <h1
                  className="text-2xl leading-8 font-bold text-center text-white">Sign in</h1>
              </div>
              <div className="flex flex-col mb-3">
                <input
                  type="text"
                  spellCheck="false"
                  className="bg-white border-2 border-white text-sm rounded-xl block w-full py-2.5 px-4 focus:outline-none font-bold text-neutral placeholder:text-base-400"
                  value={email}
                  onChange={(e) => {
                    setError(null)
                    setEmail(e.target.value)
                  }}
                  placeholder="Email address" />
              </div>
              <div className="flex flex-col mb-5">
                <input
                  type="password"
                  className="bg-white border-2 border-white text-sm rounded-xl block w-full py-2.5 px-4 focus:outline-none font-bold text-neutral placeholder:text-base-400"
                  value={password}
                  onChange={(e) => {
                    setError(null)
                    setPassword(e.target.value)
                  }}
                  placeholder="Password" />
              </div>
              <div className="flex flex-col mb-2.5">
                  <SecondaryButton
                    onClick={() => {
                      if (!email || !password) return setError('All fields are required')
                      setLoading({provider: 'email'})
                      logInWithEmailAndPassword(email, password)
                        .catch((err) => {
                          setLoading(null)
                          setError(err?.toString())
                        })
                    }}
                    disabled={!!(loading)}
                    loading={loading?.provider === 'email'}
                  >
                  Sign in
                  </SecondaryButton>
                </div>
              <div className="flex items-center text-sm justify-center mb-5">
                <Link className="font-semibold text-white" to={resetPassword}>Forgot password?</Link>
              </div>
              <div className="flex flex-col mb-10">
                  <GoogleButton
                    onClick={() => {
                      setLoading({provider: 'google'})
                      signInWithGoogle(false)
                        .catch((err) => {
                          setLoading(null)
                          setError(err?.toString())
                        })
                    }}
                    disabled={!!(loading)}
                    loading={loading?.provider === 'google'}
                  >
                    <img src="/images/google-logo.svg" className="w-5 h-5 mr-3" alt="google"/>
                    Sign in with Google
                  </GoogleButton>
                  {error && (
                    <p className="text-error bg-white rounded-md p-1 text-sm mt-3 text-center w-full">{error}</p>
                  )}
                </div>
              <div className="flex items-center text-sm justify-center font-semibold text-white">
                You don’t have an account?
                <Link className="underline ml-1" to={register}>Register here</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login