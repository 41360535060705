import React, {useEffect, useState} from 'react'
import PrimaryLayout from '../../layouts/PrimaryLayout/PrimaryLayout'
import {useSelector} from "react-redux"
import {deleteUserHandler, getUserHandler, updateUserNameHandler} from "../../utils/useraccount"
import store from "../../store/createStore"
import AccountSettingsInput from "../Settings/AccountSettingsInput"
import {addUserDataUpdate, updateUserData} from "../../store/User/user.actions";
import Alert from "../Alert/Alert";
import {useNavigate} from "react-router-dom"
import {deleteUser, getAuth} from 'firebase/auth'
import {logout} from "../../utils/firebase";
import {login} from '../../consts/links'
import PrimaryButton from "../global/PrimaryButton/PrimaryButton";
import TertiaryButton from "../global/TertiaryButton/TertiaryButton";
import {handleError} from "../../utils/functions";

interface DeleteAccountModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({setOpenModal}) => {
  const navigate = useNavigate();
  const userData = useSelector((state: Storage) => state.user);
  const auth = getAuth();
  const user = auth.currentUser;

  const deleteAccountHandler = async () => {
    if (user) {
      deleteUser(user).then(() => {
        deleteUserHandler(
          userData?.idToken || "",
          userData?.accessToken || ""
        ).then((response) => {
          if (response === 204) {
            logout();
            navigate(login);
          }
        }).catch((error) => {
          handleError(error);
          console.log(error);
        })
      }).catch((error)=>{
        if (error.code === 'auth/requires-recent-login'){
          logout();
          navigate(login);
        }
      })
    }
  };

  return (
    <div
      className={
        "flex flex-col items-center gap-6 w-full max-w-sm md:px-2"
      }
    >
      <p className="text-center font-bold text-base text-neutral">
        Are you sure you want to delete your account?  This action cannot be undone.
      </p>
      <div className="flex gap-6 md:gap-0 justify-center items-center w-full">
          <PrimaryButton
            onClick={() => {
              deleteAccountHandler().then()
            }}
          >
            <span className='md:w-36'>Yes</span>
        </PrimaryButton>
        <TertiaryButton
          onClick={() => {
            setOpenModal(false);
          }}
        >
           <span className='md:w-36'>Not now</span>
        </TertiaryButton>
      </div>
    </div>
  );
}

const AccountSettings = () => {

  const userData = useSelector((state: Storage) => state.user);
  const [updateName, setUpdateName] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>(
    userData?.name ? userData.name : ""
  );

  useEffect(() => {
    if (updateName) {
      const timeout = setTimeout(() => {
        setUpdateName(false)
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [updateName])

  useEffect(() => {
    if (userData?.email === "" || userData?.name === "") {
      getUserHandler(userData?.idToken || "", userData?.accessToken || "").then(
        (response) => {
          if ((response?.status as number) === 200) {
            store.dispatch(
              addUserDataUpdate({
                name: response?.userData?.name || response?.userData?.email,
                email: response?.userData?.email || "",
              })
            );
            setUserName(response?.userData?.name);
          }
        }
      );
    }
  }, []);
  return (
    <PrimaryLayout>
      {openModal && <Alert onDismiss={() => {
        setOpenModal(false)
      }}>
        <DeleteAccountModal setOpenModal={setOpenModal}/>
      </Alert>}
      <div className="py-8 flex flex-col">
        <div className="flex flex-col gap-9">
          <div className="flex gap-5 items-center">
            <img src="/images/account-setting.svg" alt="account setting icon"/>
            <h1 className="text-xl md:text-2xl font-bold text-neutral">
              Account settings
            </h1>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <label
                htmlFor="first_name"
                className="block text-base font-bold text-neutral"
              >
                First name
              </label>
              <div className="flex gap-8 items-center">
                <AccountSettingsInput
                  idValue={"first_name"}
                  changeInputValue={setUserName}
                  disabled={false}
                  inputValue={userName}
                  required={true}
                  updatedField={updateName}
                  updatedFieldText={"Your name has been updated!"}
                />
                {userName !== userData?.name && (
                  <TertiaryButton
                    onClick={() => {
                      updateUserNameHandler(
                        userData.idToken || "",
                        userName,
                        userData?.accessToken || ""
                      ).then((response) => {
                        if (response === 204) {
                          store.dispatch(
                            updateUserData({
                              name: userName
                            })
                          );
                          setUpdateName(true)
                        }
                      });
                    }}>
                    Save
                  </TertiaryButton>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <label
                htmlFor="user_email_address"
                className="block text-base font-bold text-neutral"
              >
                User email address
              </label>
              <AccountSettingsInput
                idValue={"user_email_address"}
                inputValue={userData?.email ? userData.email : ""}
                required={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {userData.isGoogleSignedIn === "TRUE" && (
          <h6 className="my-2.5 text-sm font-semibold text-neutral">
            You’re connected with Google
          </h6>
        )}
        <div className="pt-32">
          <div className="flex gap-6 items-center">
            <p
              className="cursor-pointer text-error text-sm font-semibold underline"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Delete account
            </p>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  )
}

export default AccountSettings
