import React from "react"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"

const PrimaryButton = ({children, id, onClick, outline, disabled, loading} : {
  children: any, id?: string, onClick?: (event?: any) => void, outline?: boolean, disabled?: boolean, loading?: boolean
}) => (
  <button
    id={id}
    className={
      `
        flex
        items-center
        justify-center
        ${outline ? 'bg-white' : 'bg-primary'}
        border
        border-primary
        text-center
        text-sm
        rounded-xl
        ${loading ? 'pl-11 pr-6' : 'px-6'}
        h-11
        ${outline ? 'text-primary' : 'text-white'}
        font-extrabold
        duration-300
        transition-colors
        hover:border-primary/80
        min-w-[200px]
        ${outline ? 'hover:text-primary/80' : 'hover:bg-primary/80'}
        active:scale-95
      `
    }
    disabled={disabled}
    onClick={onClick}
  >
    {loading ?
      <div className="flex" style={{marginLeft: '-24px'}}>
        <LoadingSpinner small/> Loading
      </div> :
      children
    }
  </button>
)

export default PrimaryButton