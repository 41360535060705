import './App.css'

import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import { createCV, home, login, register, resetPassword, settings, support } from "./consts/links"

import AccountSettings from './components/Settings/AccountSettings'
import CheckCompatibility from "./middleware/CheckCompatibility"
import Cv from "./components/Cv/Cv"
import Home from "./components/Home/Home"
import Login from "./components/Auth/Login"
import React from 'react'
import Register from "./components/Auth/Register"
import ResetPassword from "./components/Auth/ResetPassword"
import Support from './components/Support/Support'
import VideoCvPageEditor from "./components/VideoCvPageEditor/VideoCvPageEditor"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={home} element={<CheckCompatibility><Home/></CheckCompatibility>} />
        <Route path={login} element={<CheckCompatibility><Login/></CheckCompatibility>} />
        <Route path={register} element={<CheckCompatibility><Register/></CheckCompatibility>} />
        <Route path={resetPassword} element={<CheckCompatibility><ResetPassword/></CheckCompatibility>} />
        <Route path={createCV} element={<CheckCompatibility><VideoCvPageEditor/></CheckCompatibility>} />
        <Route path={settings} element={<CheckCompatibility><AccountSettings/></CheckCompatibility>} />
        <Route path={support} element={<CheckCompatibility><Support/></CheckCompatibility>} />
        <Route path="/:id" element={<Cv />} />
        {/*<Route path={record} element={<Loom />}/>*/}
      </Routes>
    </BrowserRouter>
  )
}

export default App
